import { VideoCall, Keyboard } from '@mui/icons-material';
import { TextField, InputAdornment } from '@mui/material';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '../../components/Button';
import { AppRoutes, CallSource } from '../../helpers/enums';
import { useAppDispatch } from '../../helpers/hooks';
import { cancelEyesonChannel } from '../../store/actions/eyeson';
import { leaveMeeting, updateCallSource, updateExistingAccessKey } from '../../store/slices/eyeson';
import { RootWrapperStyled } from './HomePage.styles';

const HomePage = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const [meetingAccessKey, setMeetingAccessKey] = useState('');

	const handleChangeMeetingAccessKey = useCallback((event: ChangeEvent<HTMLInputElement>) => {
		setMeetingAccessKey(event.target.value);
	}, []);

	useEffect(() => {
		dispatch(cancelEyesonChannel());
		dispatch(leaveMeeting());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleCreateRoom = useCallback(() => {
		dispatch(updateCallSource(CallSource.Create));
		navigate(AppRoutes.WaitingRoom);
	}, [dispatch, navigate]);

	const handleJoinRoom = useCallback(() => {
		dispatch(updateCallSource(CallSource.Create));
		dispatch(updateExistingAccessKey(meetingAccessKey));
		navigate(AppRoutes.WaitingRoom);
	}, [dispatch, navigate, meetingAccessKey]);

	return (
		<RootWrapperStyled>
			<Button variant='contained' size='large' startIcon={<VideoCall />} onClick={handleCreateRoom}>
				Start a Meeting
			</Button>
			<TextField
				onChange={handleChangeMeetingAccessKey}
				InputProps={{
					startAdornment: (
						<InputAdornment position='start'>
							<Keyboard />
						</InputAdornment>
					)
				}}
			/>
			<Button variant='text' size='large' onClick={handleJoinRoom} disabled={!meetingAccessKey.length}>
				Join a Meeting
			</Button>
		</RootWrapperStyled>
	);
};

export default HomePage;
