import { Box, CircularProgressProps } from '@mui/material';
import React, { PropsWithChildren, ReactElement } from 'react';

import { CircularProgressStyled } from './Loader.styles';

interface LoaderProps extends CircularProgressProps {
	isLoading: boolean;
	backdrop?: boolean;
}

/**
 * Component to display loading process.
 * Works as a wrapper. During loader may cover children components with a backdrop.
 * Uses styled `CircularProgress` component from MUI.
 *
 * @param {PropsWithChildren<LoaderProps>}
 * @returns {ReactElement}
 */
const Loader = ({ isLoading, backdrop = false, children, size = 40, ...rest }: PropsWithChildren<LoaderProps>): ReactElement => {
	return (
		<Box
			sx={{
				position: 'relative',
				...(backdrop && isLoading
					? {
						'&::after': {
							content: '""',
							position: 'absolute',
							top: 0,
							zIndex: 9,
							width: '100%',
							height: '100%',
							backgroundColor: 'rgba(0, 0, 0, 0.3)'
						}
					}
					: {})
			}}
		>
			{children}
			{isLoading && (
				<CircularProgressStyled
					sx={{
						marginTop: `${-size / 2}px`,
						marginLeft: `${-size / 2}px`
					}}
					size={size}
					{...rest}
				/>
			)}
		</Box>
	);
};

export default Loader;
