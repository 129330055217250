import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface LoadingState {
	isStartUpLoading: boolean;
  isAuthLoading: boolean;
  isGetRoomLoading: boolean;
	isRoomConnectLoading: boolean;
}

const initialState: LoadingState = {
	isStartUpLoading: false,
	isAuthLoading: false,
	isGetRoomLoading: false,
	isRoomConnectLoading: false
};

const loadingSlice = createSlice({
	name: 'loading',
	initialState,
	reducers: {
		setStartUpLoading: (state: LoadingState, { payload }: PayloadAction<boolean>) => ({ ...state, isStartUpLoading: payload }),
		setAuthLoading: (state: LoadingState, { payload }: PayloadAction<boolean>) => ({ ...state, isAuthLoading: payload }),
		setGetRoomLoading: (state: LoadingState, { payload }: PayloadAction<boolean>) => ({ ...state, isGetRoomLoading: payload }),
		setRoomConnectLoading: (state: LoadingState, { payload }: PayloadAction<boolean>) => ({ ...state, isRoomConnectLoading: payload })
	}
});

export const {
	setStartUpLoading,
	setAuthLoading,
	setGetRoomLoading,
	setRoomConnectLoading
} = loadingSlice.actions;

export default loadingSlice.reducer;
