import { PayloadAction } from '@reduxjs/toolkit';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';

import { registerUser, authenticate, UserBodyParams } from '../../../api';
import { USER_REGISTER } from '../../../helpers/constants';
import { setAuthLoading } from '../../slices/loading';
import { setUser } from '../../slices/user';

function* makeFetch({ payload }: PayloadAction<Required<UserBodyParams>>): SagaIterator {
	try {
		yield put(setAuthLoading(true));
		const { email } = yield call(registerUser, payload);
		// TODO: add UI to split registration and authentication processes
		const data = yield call(authenticate, { ...payload, email });

		yield put(setUser(data));
	}
	catch (error) {
		// TODO: add error proceeding
		console.error(error);
	}
	finally {
		yield put(setAuthLoading(false));
	}
}

export function* watch() {
	yield takeEvery(USER_REGISTER, makeFetch);
}
