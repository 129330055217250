import { CSSProperties } from '@mui/styled-engine';
import React, { ReactElement, useEffect, useRef } from 'react';

import { RootWrapperStyled, VideoStyled } from './Stream.styles';

interface VideoProps {
	width?: CSSProperties['width'];
	src: MediaStream | null;
	isPreview?: boolean;
	sinkId?: string;
	muted?: boolean;
}

/**
 * Component to display a styled `video` tag.
 * `Eyson`remote stream comes with `src` prop.
 *
 * @param {VideoProps}
 * @returns {ReactElement}
 */
const Stream = ({
	src,
	width = 1,
	isPreview = false,
	sinkId,
	muted = false
}: VideoProps): ReactElement => {
	const videoRef = useRef<HTMLVideoElement>(null);

	useEffect(() => {
		if (src && videoRef.current) {
			videoRef.current.srcObject = src;
		}
	}, [src]);
	useEffect(() => {
		if (sinkId && videoRef.current) {
			// @ts-ignore
			videoRef.current?.setSinkId(sinkId);
		}
	}, [sinkId]);

	return (
		<RootWrapperStyled width={width} isPreview={isPreview}>
			<VideoStyled ref={videoRef} playsInline autoPlay isPreview={isPreview} muted={muted} />
		</RootWrapperStyled>
	);
};

export default Stream;
