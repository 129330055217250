import styled from '@emotion/styled';
import { Box, Select as MuiSelect } from '@mui/material';

export const RootWrapperStyled = styled(Box)`
	display: flex;
	flex-direction: column;
	/* column-gap: ${(props) => props.theme.spacing(2)};
	width: 90%; */
	margin: ${(props) => props.theme.spacing(6)} auto 0;
`;

export const VideoWrapper = styled.div`
	max-height: 40vh;
	min-height: 19rem;
	display: flex;
  justify-content: center;
  align-items: center;
`;

export const Select = styled(MuiSelect)`
	width: 120px;
`;
