import Logger from '../Logger';
import BaseEvent from './BaseEvent';
import {
  hasVideo,
  hasAudio,
  createExternalStream
} from '../utils/StreamHelpers';

/**
 * ChangeStreamEvent "changes" a stream with the given options.
 *
 * The MediaStream is only updated if the Browser turns off device activity
 * indicators on disable (i.e. setting the video tracks `.enabled` prop to
 * false). This is the case for Firefox (May 2019).
 *
 * For other Browsers, a totally new MediaStream is acquired. Although some
 * of its tracks might be re-used / kept alive.
 * See `.setStream` in SDH for more details of current implementation.
 **/
class ReplaceStreamEvent extends BaseEvent {
  // eslint-disable-next-line max-statements
  handle(msg) {
    const stream = createExternalStream(msg.stream);
    if (!stream) {
      Logger.error('ReplaceStreamEvent::handle invalid stream', msg.stream);
      return Promise.reject(new TypeError('Invalid stream'));
    }
    const { _session, _connection } = this.context;
    if (_session.micMixer) {
      _session.micMixer.destroy();
      _session.micMixer = null;
    }
    if (_session.vbgMixer) {
      _session.vbgMixer.stopOriginalStream();
      _session.vbgMixer.terminate();
    }

    _session.options.audio = hasAudio(stream);
    _session.options.video = hasVideo(stream);

    return _session
      .setStream(stream)
      .then(() => {
        _session.externalStream = stream;
        _connection.hasExternalStream = true;
        _session.send({ type: 'mute_video', on: !hasVideo(stream) });
      })
      .catch(err => {
        _session.externalStream = null;
        _connection.hasExternalStream = false;
        Logger.error(err);
      });
  }
}

export default ReplaceStreamEvent;
