import { AxiosError } from 'axios';
import { SagaIterator } from 'redux-saga';
import { put, call, takeEvery } from 'redux-saga/effects';

import { checkCookie } from '../../../api';
import { USER_STARTUP } from '../../../helpers/constants';
import { AppRoutes } from '../../../helpers/enums';
import { toastWarning } from '../../../helpers/services/toasts';
import { setStartUpLoading } from '../../slices/loading';
import { setEmail } from '../../slices/user';

function* makeFetch(): SagaIterator {
	try {
		yield put(setStartUpLoading(true));

		// TODO: fix types and return value of `checkCookie`. It should be possible to use `setUser` action instead of `setEmail`
		const data: { email: string } = yield call(checkCookie);

		yield put(setEmail(data));
	}
	catch (error) {
		if (
			error instanceof AxiosError &&
			error?.response?.data.status === 401 &&
			window.location.pathname !== `/${AppRoutes.Login}`
		) {
			yield call(toastWarning, 'Login required to proceed!');
		}
	}
	finally {
		yield put(setStartUpLoading(false));
	}
}

export function* watch() {
	yield takeEvery(USER_STARTUP, makeFetch);
}
