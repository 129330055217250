import { Alert, AlertProps, Typography } from '@mui/material';
import React, { ReactElement } from 'react';

interface ToastMessageProps extends AlertProps {
  message: string;
}

/**
 * Component to display a toast message if an error or a warning occurs.
 *
 * @param {ToastMessageProps}
 * @returns {ReactElement}
 */
const ToastMessage = ({ message, ...rest }: ToastMessageProps): ReactElement => (
	<Alert {...rest}>
		<Typography>{message}</Typography>
	</Alert>
);

export default ToastMessage;
