import {
	CallEnd,
	Dashboard,
	Group,
	Mic,
	MicOff,
	Videocam,
	VideocamOff,
	ScreenShare,
	StopScreenShare
} from '@mui/icons-material';
import { Box, IconButton, Toolbar, Typography } from '@mui/material';
import eyeson, { StreamHelpers } from 'eyeson';
import React, { ReactElement, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { AppRoutes } from '../../../../helpers/enums';
import { useAppDispatch, useAppSelector } from '../../../../helpers/hooks';
import { cancelEyesonChannel } from '../../../../store/actions/eyeson';
import { toggleAudio, toggleScreenSharing, toggleVideo } from '../../../../store/slices/eyeson';
import SoundLevelMeter from '../../../WaitingRoom/components/SoundLevelMeter';
import { ActionsWrapperStyled } from './ActionsToolbar.styles';

interface ActionsToolbarProps {
	onToggleUsersDialog: () => void;
	onToggleLayoutsDialog: () => void;
}

/**
 * Displays a `Toolbar` with actions required during a meeting.
 * As props receives only dialog handlers. Other actions use redux actions.
 * @param {ActionsToolbarProps}
 * @returns {ReactElement}
 */
const ActionsToolbar = ({
	onToggleUsersDialog,
	onToggleLayoutsDialog
}: ActionsToolbarProps): ReactElement => {
	const dispatch = useAppDispatch();

	const localStream = useAppSelector((state) => state.eyeson.localStream);
	const roomAccessToken = useAppSelector((state) => state.eyeson.guestAccessKey);
	const isAudioEnabled = useAppSelector((state) => state.eyeson.isAudioEnabled);
	const isVideoEnabled = useAppSelector((state) => state.eyeson.isVideoEnabled);
	const isScreenSharingEnabled = useAppSelector((state) => state.eyeson.isScreenSharingEnabled);

	const navigate = useNavigate();

	const handleToggleAudio = useCallback(() => {
		if (localStream) {
			StreamHelpers.toggleAudio(localStream, !isAudioEnabled);
			dispatch(toggleAudio(!isAudioEnabled));
		}
	}, [dispatch, isAudioEnabled, localStream]);

	const handleToggleVideo = useCallback(() => {
		if (localStream) {
			dispatch(toggleVideo(!isVideoEnabled));
			eyeson.send({
				type: 'change_stream',
				stream: localStream,
				video: !isVideoEnabled,
				audio: isAudioEnabled
			});
		}
	}, [dispatch, isVideoEnabled, localStream, isAudioEnabled]);

	const handleToggleScreen = () => {
		if (!isScreenSharingEnabled) {
			eyeson.send({
				type: 'start_screen_capture',
				audio: isAudioEnabled,
				screenStream: null,
				screen: true
			});
			dispatch(toggleScreenSharing(true));
		}
		else {
			eyeson.send({ type: 'stop_presenting' });
		}
	};

	const handleLeaveMeeting = useCallback(() => {
		dispatch(cancelEyesonChannel());
		navigate(AppRoutes.Home);
	}, [dispatch, navigate]);

	return (
		<Toolbar>
			<ActionsWrapperStyled flexDirection='column'>
				<Typography variant='body1' color='white.main'>
					{roomAccessToken}
				</Typography>
				<Box display='block' width='100%'>
					<SoundLevelMeter isAudioEnabled={isAudioEnabled} />
				</Box>
			</ActionsWrapperStyled>
			<ActionsWrapperStyled justifyContent='center'>
				<IconButton aria-label='toggleAudio' onClick={handleToggleAudio}>
					{isAudioEnabled ? <Mic /> : <MicOff />}
				</IconButton>
				<IconButton aria-label='toggleVideo' onClick={handleToggleVideo}>
					{isVideoEnabled ? <Videocam /> : <VideocamOff />}
				</IconButton>
				<IconButton aria-label='toggleScreenSharing' onClick={handleToggleScreen}>
					{isScreenSharingEnabled ? <StopScreenShare /> : <ScreenShare />}
				</IconButton>
				<IconButton aria-label='leaveMeeting' onClick={handleLeaveMeeting} color='error'>
					<CallEnd />
				</IconButton>
			</ActionsWrapperStyled>
			<ActionsWrapperStyled justifyContent='flex-end'>
				<IconButton aria-label='changeLayout' onClick={onToggleLayoutsDialog}>
					<Dashboard />
				</IconButton>
				<IconButton aria-label='addUsers' onClick={onToggleUsersDialog}>
					<Group />
				</IconButton>
			</ActionsWrapperStyled>
		</Toolbar>
	);
};

export default ActionsToolbar;
