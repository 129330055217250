import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AuthenticateResult } from '../../../api';

export interface UserState extends Partial<Omit<AuthenticateResult, '_id'>> {
	id?: string;
	username?: string;
	authenticated: boolean;
}

const initialState: UserState = {
	authenticated: false
};

const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setUser: (_, { payload }: PayloadAction<AuthenticateResult>) => {
			const { _id: id, email, ...rest } = payload;
			const username = email.split('@')[0];

			return {
				id,
				username,
				email,
				authenticated: true,
				...rest
			};
		},
		setEmail: (state, { payload }: PayloadAction<{ email: string }>) => {
			const { email } = payload;
			const username = email.split('@')[0];

			return {
				...state,
				username,
				email,
				authenticated: true
			};
		},
		removeUser: () => {
			return initialState;
		}
	}
});

export const { setUser, setEmail, removeUser } = userSlice.actions;

export default userSlice.reducer;
