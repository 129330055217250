import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const RootWrapperStyled = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100vw;
  height: 100vh;
  background-color: ${props => props.theme.palette.black.main};
`;
