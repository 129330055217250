import { Typography } from '@mui/material';
import React, { ReactElement } from 'react';

import CopyToClipboard from '../../../../components/CopyToClipboard';
import PersistentDrawer, { PersistentDrawerProps } from '../../../../components/PersistentDrawer';
import { useAppSelector } from '../../../../helpers/hooks';

/**
 * Displays a `Drawer` with UI to invite and manage users.
 * @returns {ReactElement}
 */
const UsersDialog = ({ open, onClose }: Pick<PersistentDrawerProps, 'open' | 'onClose'>): ReactElement => {
	const roomGuestAccessKey = useAppSelector((state) => state.eyeson.guestAccessKey);

	return (
		<PersistentDrawer open={open} onClose={onClose}>
			<Typography mb={2} variant='h5'>Users</Typography>
			<Typography mb={2} variant='body1'>Copy this link and send it to people that you want to meet with.</Typography>
			{/* TODO: Change 'https://app.eyeson.team/guest=' to an appropriate link. */}
			<CopyToClipboard value={roomGuestAccessKey} />
		</PersistentDrawer>
	);
};

export default UsersDialog;
