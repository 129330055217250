import React from 'react';
import { Routes, Route } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

import ToastContainer from './components/ToastContainer';
import ErrorBoundary from './helpers/ErrorBoundary';
import routes from './routes';
import PrivateRoute from './routes/components/PrivateRoute';
import StartupContainer from './StartupContainer';

function App() {
	return (
		<ErrorBoundary>
			<StartupContainer>
				<Routes location={location}>
					<Route path='/'>
						{routes.map(({ layout: Layout, routes, name: layoutName }) => (
							<Route key={layoutName} element={<Layout />}>
								{routes.map(({ component: Component, name, isProtected, ...rest }) => (
									<Route
										key={name}
										element={
											<PrivateRoute isProtected={isProtected}>
												<Component />
											</PrivateRoute>
										}
										{...rest}
									/>
								))}
							</Route>
						))}
					</Route>
				</Routes>
			</StartupContainer>
			<ToastContainer />
		</ErrorBoundary>
	);
}

export default App;
