import Logger from './Logger.js';
import { createConsumer } from '@rails/actioncable';

/**
 * Registers with provided token and room.
 **/
class ActionCableConnection {
  constructor(uri) {
    this.channels = {};
    this.onMessageHandler = this.defaultMessageHandler;

    this.onReceived = this.onReceived.bind(this);
    this.offMessage = this.offMessage.bind(this);
    this.onConnected = this.onConnected.bind(this);
    this.startSession = this.startSession.bind(this);
    this.onDisconnected = this.onDisconnected.bind(this);

    this.cable = createConsumer(uri);
  }

  startSession() {
    this.channels.RoomChannel = this.cable.subscriptions.create(
      { channel: 'RoomChannel' },
      {
        connected: this.onConnected,
        received: this.onReceived,
        disconnected: this.onDisconnected
      }
    );
    this.channels.UserChannel = this.cable.subscriptions.create(
      { channel: 'UserChannel' },
      { received: this.onReceived }
    );
  }

  onConnected() {
    if (this.disconnectTimestamp) {
      this.handleReconnect();
    }
    Logger.debug('ActionCableConnection::onConnected');
  }

  handleReconnect() {
    this.onMessageHandler({ type: 'reconnect' });
  }

  onReceived(msg) {
    Logger.debug('ActionCableConnection::onReceived', msg);
    msg._src = 'actioncable';
    this.onMessageHandler(msg);
  }

  onDisconnected() {
    Logger.debug('ActionCableConnection::onDisconnected');
    this.disconnectTimestamp = Date.now();
    this.onMessageHandler({ type: 'disconnect' });
  }

  onMessage(onMessageHandler) {
    if (typeof onMessageHandler !== 'function') {
      return;
    }
    this.onMessageHandler = onMessageHandler;
  }

  offMessage() {
    this.onMessageHandler = this.defaultMessageHandler;
  }

  defaultMessageHandler(msg) {
    Logger.debug('ActionCableConnection::defaultMessageHandler: ', msg);
  }

  /**
   * msg has the format { channel: 'xyz', message: 'abc' }
   **/
  send({ channel = 'RoomChannel', message = 'ping' }) {
    const subscription = this.channels[channel];

    if (!subscription) {
      Logger.warn(
        'ActionCableConnection::send no subscription found! ',
        channel
      );
      return;
    }

    subscription.perform(message, { message: message });
  }

  close() {
    this.cable.disconnect();
    this.offMessage();
    this.channels = {};
    this.cable = null;
  }
}

export default ActionCableConnection;
