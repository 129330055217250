import { createAction } from '@reduxjs/toolkit';

import { UserBodyParams } from '../../../api';
import { USER_LOGIN, USER_REGISTER, USER_STARTUP } from '../../../helpers/constants';

/**
 * Register new user.
 */
export const registerAction = createAction<UserBodyParams>(USER_REGISTER);

/**
 * Login a user.
 */
export const loginAction = createAction<UserBodyParams>(USER_LOGIN);

/**
 * Checks if user is already authenticated according to cookie `authToken` existing
 */
export const startupAction = createAction(USER_STARTUP);
