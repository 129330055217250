import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AcceptEvent, StreamUpdate } from 'eyeson';

import { CallSource, RoomLayouts, RoomCustomLayoutMarks } from '../../../helpers/enums';

export interface EyesonState {
	/**
	 * Checks if connection is established. Better consider to use built in connection states of `eyeson`.
	 * @deprecated
	 */
	connected: boolean;
	/**
	 * Access token that is used to enter to a room.
	 */
	accessKey: string;
	/**
	 * Access token that is used to share a room link, to additional users may join to a meeting.
	 */
	guestAccessKey: string;
	/**
	 * List of users ids joined to a meeting.
	 * Use cases: layout setup, chat, etc.
	 */
	users: string[];
	/**
	 * Layout type that's used to display a video stream.
	 */
	layout: RoomLayouts;
	/**
	 * Custom layout possible aspect ratios.
	 */
	layoutAspectRatio: RoomCustomLayoutMarks;
	/**
	 * Current user `MediaStream`.
	 */
	localStream: MediaStream | null;
	/**
	 * Main `MediaStream`.
	 */
	remoteStream: MediaStream | null;
	/**
	 * Checks if current user audio enabled.
	 */
	isAudioEnabled: boolean;
	/**
	 * Checks if current user video streaming enabled.
	 */
	isVideoEnabled: boolean;
	/**
	 * Checks if current user screen sharing enabled.
	 */
	isScreenSharingEnabled: boolean;
	/**
	 * Id of media device for speaker (audio output source)
	 */
	sinkId: string;
	/**
	 * How this call was initiated.
	 */
	callSource: CallSource | null;
	/**
	 * Key from another meeting.
	 */
	existingAccessKey: string;
}

const initialState: EyesonState = {
	connected: false,
	accessKey: '',
	guestAccessKey: '',
	users: [],
	layout: RoomLayouts.Auto,
	layoutAspectRatio: RoomCustomLayoutMarks.Medium,
	localStream: null,
	remoteStream: null,
	isAudioEnabled: true,
	isVideoEnabled: true,
	isScreenSharingEnabled: false,
	callSource: null,
	existingAccessKey: '',
	sinkId: ''
};

const eyesonSlice = createSlice({
	name: 'eyeson',
	initialState,
	reducers: {
		setAccessKey: (state: EyesonState, { payload: accessKey }: PayloadAction<string>) => ({
			...state,
			accessKey
		}),
		setGuestAccessKey: (state: EyesonState, { payload: guestAccessKey }: PayloadAction<string>) => ({
			...state,
			guestAccessKey
		}),
		accept: (state: EyesonState, { payload }: PayloadAction<AcceptEvent>) => {
			const { localStream, remoteStream } = payload;
			return {
				...state,
				connected: true,
				localStream,
				remoteStream
			};
		},
		streamUpdate: (state: EyesonState, { payload }: PayloadAction<StreamUpdate>) => {
			const { stream, localStream } = payload;
			return {
				...state,
				localStream: localStream || state.localStream,
				remoteStream: stream || state.remoteStream
			};
		},
		addUser: (state: EyesonState, { payload }: PayloadAction<string>) => ({
			...state,
			users: [...state.users, payload]
		}),
		removeUser: (state: EyesonState, { payload }: PayloadAction<string>) => ({
			...state,
			users: state.users.filter((userId) => userId !== payload)
		}),
		toggleAudio: (state: EyesonState, { payload: isAudioEnabled }: PayloadAction<boolean>) => ({
			...state,
			isAudioEnabled
		}),
		toggleVideo: (state: EyesonState, { payload: isVideoEnabled }: PayloadAction<boolean>) => ({
			...state,
			isVideoEnabled
		}),
		toggleScreenSharing: (
			state: EyesonState,
			{ payload: isScreenSharingEnabled }: PayloadAction<boolean>
		) => ({
			...state,
			isScreenSharingEnabled
		}),
		setLayout: (state: EyesonState, { payload }: PayloadAction<RoomLayouts>) => ({
			...state,
			layout: payload
		}),
		updateCallSource: (state: EyesonState, { payload }: PayloadAction<CallSource>) => ({
			...state,
			callSource: payload
		}),
		updateExistingAccessKey: (state: EyesonState, { payload }: PayloadAction<string>) => ({
			...state,
			existingAccessKey: payload
		}),
		updateSinkId: (state: EyesonState, { payload }: PayloadAction<string>) => ({
			...state,
			sinkId: payload
		}),
		setCustomLayoutAspectRatio: (
			state: EyesonState,
			{ payload }: PayloadAction<RoomCustomLayoutMarks>
		) => ({ ...state, layoutAspectRatio: payload }),
		leaveMeeting: () => initialState
	}
});

export const {
	setAccessKey,
	setGuestAccessKey,
	accept,
	streamUpdate,
	addUser,
	removeUser,
	toggleAudio,
	toggleVideo,
	toggleScreenSharing,
	setLayout,
	updateCallSource,
	updateExistingAccessKey,
	updateSinkId,
	setCustomLayoutAspectRatio,
	leaveMeeting
} = eyesonSlice.actions;

export default eyesonSlice.reducer;
