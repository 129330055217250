import eyeson, { EyesonEvent } from 'eyeson';
import { EventChannel, eventChannel, SagaIterator, Task } from 'redux-saga';
import {
	call,
	cancel,
	cancelled,
	CancelledEffect,
	put,
	take,
	fork,
	TakeEffect,
	delay,
	select
} from 'redux-saga/effects';

import { RootState } from '../..';
import { EYESON_CANCEL_CHANNEL, EYESON_INIT_CHANNEL } from '../../../helpers/constants';
import { EyesonConnectionStatus, EyesonEventType } from '../../../helpers/enums';
import {
	accept,
	addUser,
	leaveMeeting,
	setGuestAccessKey,
	streamUpdate,
	toggleScreenSharing
} from '../../slices/eyeson';
import { setRoomConnectLoading } from '../../slices/loading';

/**
 * Create an event channel for `eyeson`.
 * Setup subscription to incoming `eyeson` events.
 * @returns {EventChannel<EyesonEvent>}
 */
function createChannel(): EventChannel<EyesonEvent> {
	return eventChannel((emitter) => {
		const handleEvent = (event: EyesonEvent) => {
			emitter(event);
		};
		console.log('createChannel');
		eyeson.onEvent(handleEvent);

		const unsubscribe = () => {
			eyeson.offEvent(handleEvent);
			eyeson.destroy();

			eyeson.onEvent(handleEvent);
		};

		return unsubscribe;
	});
}

/**
 * Initialize `eyeson` saga channel listener.
 * All `eyeson` events should be handled in scope of this saga.
 */
export function* init(): SagaIterator {
	const channel: EventChannel<EyesonEvent> = yield call(createChannel);

	while (true) {
		try {
			const event: EyesonEvent = yield take(channel);
			// TODO: remove `console.log` when all event handlers are implemented.s
			// eslint-disable-next-line no-console
			console.debug(event.type, event);

			switch (event.type) {
				case EyesonEventType.Accept:
					yield put(accept(event));
					yield put(setRoomConnectLoading(false));
					break;
				case EyesonEventType.StreamUpdate:
					yield put(streamUpdate(event));
					yield put(setRoomConnectLoading(false));
					break;
				case EyesonEventType.RoomReady:
					if (event.content.ready) {
						yield put(setGuestAccessKey(event.content.room.guest_token));
						yield put(setRoomConnectLoading(false));
					}
					break;
				case EyesonEventType.Connection:
					if (event.connectionStatus === EyesonConnectionStatus.FetchRoom) {
						yield put(setRoomConnectLoading(true));
					}
					if (event.connectionStatus === EyesonConnectionStatus.Connected) {
						yield put(setRoomConnectLoading(false));
					}
					break;
				case EyesonEventType.OptionsUpdate:
					/**
					 * This case is required to share room layout name between users.
					 * Currently, it's not possible to share custom layout name.
					 * Probably layout name property (the one is sent with `/rooms/:access_key/layout`) is considered for auto layout only.
					 * Contact eyeson team or find own solution with BE integration.
					 */
					break;
				case EyesonEventType.AddUser:
					yield put(addUser(event.user.id));
					break;
				case EyesonEventType.PresentationEnded: {
					let isAudioEnabled = true;
					let isVideoEnabled = true;
					try {
						isAudioEnabled = yield select((state: RootState) => state.eyeson.isAudioEnabled);
						isVideoEnabled = yield select((state: RootState) => state.eyeson.isVideoEnabled);
					}
					catch (error) {
						console.error(error);
					}
					eyeson.send({
						type: 'start_stream',
						audio: isAudioEnabled,
						video: isVideoEnabled
					});
					yield put(toggleScreenSharing(false));
					break;
				}
				default:
					break;
			}
		}
		catch (error) {
			// TODO: add additional error handler
			channel.close();
		}
		finally {
			if ((yield cancelled()) as CancelledEffect) {
				channel.close();

				yield delay(500);
				yield put(leaveMeeting());
			}
		}
	}
}

/**
 * @description This watcher uses `while` construction to intercept several actions. 1st - to initiate `createChannel`, and 2nd - to cancel `createChannel`.
 */
export function* watch() {
	// eslint-disable-next-line no-unmodified-loop-condition
	while ((yield take(EYESON_INIT_CHANNEL)) as TakeEffect) {
		const initedChannel: Task[] = yield fork(init);

		yield take(EYESON_CANCEL_CHANNEL);

		yield cancel(initedChannel);
	}
}
