import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';

import { authenticate, UserBodyParams } from '../../../api';
import { USER_LOGIN } from '../../../helpers/constants';
import { toastSuccess, toastError } from '../../../helpers/services/toasts';
import { setAuthLoading } from '../../slices/loading';
import { setUser } from '../../slices/user';

function* makeFetch({ payload }: PayloadAction<UserBodyParams>): SagaIterator {
	try {
		yield put(setAuthLoading(true));

		const data = yield call(authenticate, payload);
		yield put(setUser(data));
		yield call(toastSuccess, 'Authenticated');
	}
	catch (error) {
		if (error instanceof AxiosError && error.response?.data?.detail) {
			yield call(toastError, error.response.data.detail);
		}
	}
	finally {
		yield put(setAuthLoading(false));
	}
}

export function* watch() {
	yield takeEvery(USER_LOGIN, makeFetch);
}
