import { createAction } from '@reduxjs/toolkit';

import { EYESON_CANCEL_CHANNEL, EYESON_CHANGE_ROOM_LAYOUT, EYESON_GET_ROOM, EYESON_INIT_CHANNEL } from '../../../helpers/constants';

/**
 * Trigger an api call to create an `eyeson` room or join to an existed one.
 */
export const getRoom = createAction<string | undefined>(EYESON_GET_ROOM);

/**
 * Initiates `createChannel` saga channel, that setup subscription to incoming `eyeson` events.
 */
export const initEyesonChannel = createAction(EYESON_INIT_CHANNEL);

/**
 * Forces `createChannel` saga channel to close and close `eyeson` connection eventually.
 */
export const cancelEyesonChannel = createAction(EYESON_CANCEL_CHANNEL);

/**
 * Trigger an api call to change a room layout.
 */
export const changeRoomLayout = createAction(EYESON_CHANGE_ROOM_LAYOUT);
