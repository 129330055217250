import styled from '@emotion/styled';
import { ToastContainer } from 'react-toastify';

export const ToastContainerStyles = styled(ToastContainer)`
	.Toastify__toast {
		padding: 0;
		min-height: 0;
	}
	.Toastify__toast-body {
		margin: 0;
		padding: 0;
	}
`;
