import React from 'react';
import { toast, ToastOptions } from 'react-toastify';

import ToastMessage from '../../components/ToastMessage';

/**
 * Toast service to display success message
 *
 * @param messsage string
 * @param options ToastOptions
 */
export const toastSuccess = (messsage: string, options?: ToastOptions): void => {
	toast(({ closeToast }) => <ToastMessage severity='success' onClose={closeToast} message={messsage} />, options);
};

/**
 * Toast service to display error message
 *
 * @param messsage string
 * @param options ToastOptions
 */
export const toastError = (messsage: string, options?: ToastOptions): void => {
	toast(({ closeToast }) => <ToastMessage severity='error' onClose={closeToast} message={messsage} />, options);
};

/**
 * Toast service to display warning message
 *
 * @param messsage string
 * @param options ToastOptions
 */
export const toastWarning = (messsage: string, options?: ToastOptions): void => {
	toast(({ closeToast }) => <ToastMessage severity='warning' onClose={closeToast} message={messsage} />, options);
};
