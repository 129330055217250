import React, { ReactElement } from 'react';
import { toast } from 'react-toastify';

import { ToastContainerStyles } from './ToastContainer.styles';

const ToastContainer = (): ReactElement => (
	<ToastContainerStyles
		hideProgressBar
		closeButton={false}
		position={toast.POSITION.BOTTOM_RIGHT}
		autoClose={5000}
	/>
);

export default ToastContainer;
