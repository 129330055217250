import React, { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';

import { AppRoutes } from '../../../helpers/enums';
import { useAppSelector } from '../../../helpers/hooks';

interface PrivateRouteProps {
  isProtected?: boolean;
	children: ReactElement;
}

/**
 * `PrivateRoute` checks if a Route requires a user to be authenticated
 * @param {PrivateRouteProps}
 * @returns ReactElement
 */
const PrivateRoute = ({ isProtected, children }: PrivateRouteProps): ReactElement => {
	const authenticated = useAppSelector((state) => state.user.authenticated);

	// TODO: add a logic to check authentication
	if (isProtected && !authenticated) {
		return <Navigate to={{ pathname: AppRoutes.Login }} replace />;
	}

	return children;
};

export default PrivateRoute;
