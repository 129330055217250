/* eslint-disable max-lines */
/* global gapi */
import Logger from '../Logger.js';

const API_URL = 'https://www.googleapis.com/youtube/v3';

/**
 * YouTube API Helper.
 *
 * Documenation is available at:
 * - https://developers.google.com/youtube/v3/live/life-of-a-broadcast
 * - https://developers.google.com/youtube/v3/live/docs/liveBroadcasts
 *
 **/
class YouTubeApi {
  /* eslint-disable max-statements */
  constructor(eyeson) {
    this.eyeson = eyeson;
    this.apiKey = eyeson.config.youtube.key;
    this.clientId = eyeson.config.youtube.client;
    this.activated = null;

    if (!this.apiKey || !this.clientId) {
      return;
    }

    // keep track of broadcastId for deactivation and error
    this.broadcastId = null;

    this.activate = this.activate.bind(this);
    this.getStream = this.getStream.bind(this);
    this.deactivate = this.deactivate.bind(this);
    this.startStream = this.startStream.bind(this);
    this.handleError = this.handleError.bind(this);
    this.insertStream = this.insertStream.bind(this);
    this.clearInterval = this.clearInterval.bind(this);
    this.insertBroadcast = this.insertBroadcast.bind(this);
    this.createLiveStream = this.createLiveStream.bind(this);
    this.cleanupBroadcast = this.cleanupBroadcast.bind(this);

    this.initGoogleApi();
  }
  /* eslint-enable max-statements */

  initGoogleApi() {
    if (!document || typeof gapi !== 'undefined') {
      return;
    }

    const ga = document.createElement('script');
    ga.type = 'text/javascript';
    ga.async = true;
    ga.onload = this.handleClientLoad.bind(this);
    ga.src = '//apis.google.com/js/api.js';
    document.body.appendChild(ga);
  }

  handleClientLoad() {
    gapi.load('client:auth2', this.initClient.bind(this));
  }

  initClient() {
    gapi.client.init({
      apiKey: this.apiKey,
      clientId: this.clientId,
      scope: 'https://www.googleapis.com/auth/youtube.force-ssl'
    });

    if (this.activated) {
      this.activate();
    }
  }

  activate() {
    try {
      gapi.auth2
        .getAuthInstance()
        .signIn({ prompt: 'select_account' })
        .then(this.createLiveStream, this.handleError);
    } catch (error) {
      this.activated = true;
      this.handleError();
    }
  }

  deactivate() {
    Logger.warn('YouTubeApi::deactivate');
    this.clearInterval();
    this.cleanupBroadcast();

    // make sure we always stop the stream on our side at least.
    this.eyeson.send({ type: 'stop_youtube' });
  }

  getDefaultTitle() {
    return `Live Stream ${new Date().toDateString()}`;
  }

  // Valid privacy settings are:
  // unlisted, public, private
  insertBroadcast() {
    return gapi.client.request({
      path: API_URL + '/liveBroadcasts',
      method: 'POST',
      params: {
        part: 'snippet,status'
      },
      body: {
        snippet: {
          title: this.getDefaultTitle(),
          scheduledStartTime: new Date().toISOString()
        },
        status: {
          privacyStatus: 'public'
        }
      }
    });
  }

  listBroadcast(broadcastId) {
    return gapi.client.request({
      path: API_URL + '/liveBroadcasts',
      method: 'GET',
      params: {
        id: broadcastId,
        part: 'id,contentDetails,status'
      }
    });
  }

  insertStream() {
    return gapi.client.request({
      path: API_URL + '/liveStreams',
      method: 'POST',
      params: {
        part: 'snippet,cdn'
      },
      body: {
        snippet: {
          title: this.getDefaultTitle()
        },
        cdn: {
          resolution: 'variable',
          frameRate: 'variable',
          ingestionType: 'rtmp'
        }
      }
    });
  }

  bindBroadcast(broadcastId, streamId) {
    return gapi.client.request({
      path: API_URL + '/liveBroadcasts/bind',
      method: 'POST',
      params: {
        id: broadcastId,
        streamId: streamId,
        part: 'id,contentDetails'
      }
    });
  }

  // After you call the liveBroadcasts.transition method, it may take several
  // seconds, or even up to a minute, for that transition to complete. During
  // that time, you should poll the API to check the broadcast's status. Until
  // the transition is complete, the broadcast's status will be testingStarting.
  transitionBroadcast(broadcastId, status) {
    return gapi.client.request({
      path: API_URL + '/liveBroadcasts/transition',
      method: 'POST',
      params: {
        id: broadcastId,
        part: 'id,contentDetails',
        broadcastStatus: status
      }
    });
  }

  deleteBroadcast(broadcastId) {
    return gapi.client.request({
      path: API_URL + '/liveBroadcasts',
      method: 'DELETE',
      params: {
        id: broadcastId
      }
    });
  }

  // This is quite the process, but the calls here mirror what the guide says.
  // See here: https://developers.google.com/youtube/v3/live/life-of-a-broadcast
  // and https://stackoverflow.com/a/35083880/980524
  createLiveStream() {
    let broadcastId = null;
    let streamId = null;

    this.insertBroadcast()
      .then(resp => {
        broadcastId = JSON.parse(resp.body).id;
        // keep track of broadcastId for deactivate()
        this.broadcastId = broadcastId;
        return this.insertStream();
      })
      .then(resp => {
        streamId = JSON.parse(resp.body).id;
        return this.bindBroadcast(broadcastId, streamId);
      })
      .then(() => this.getStream(streamId))
      .then(resp => this.startStream(broadcastId, JSON.parse(resp.body)))
      .then(() => this.waitForStatus(() => this.getStream(streamId), 'active'))
      .then(() => this.transitionBroadcast(broadcastId, 'testing'))
      .then(() => {
        return this.waitForStatus(
          () => this.getBroadcast(broadcastId),
          'testing'
        );
      })
      .then(() => this.transitionBroadcast(broadcastId, 'live'))
      .then(() =>
        this.eyeson.send({ type: 'status_update_youtube', status: 'live' })
      )
      .catch(this.handleError);
  }

  getStream(streamId) {
    return gapi.client.request({
      path: API_URL + '/liveStreams',
      params: {
        part: 'cdn,status',
        id: streamId
      }
    });
  }

  getBroadcast(broadcastId) {
    return gapi.client.request({
      path: API_URL + '/liveBroadcasts',
      params: {
        part: 'id,status',
        id: broadcastId
      }
    });
  }

  getViewersCount(broadcastId) {
    return gapi.client
      .request({
        path: API_URL + '/videos',
        params: {
          id: broadcastId,
          part: 'liveStreamingDetails'
        }
      })
      .then(response => {
        const [video] = response.result.items;
        if (!video) {
          return null;
        }
        return video.liveStreamingDetails.concurrentViewers;
      });
  }

  startStream(broadcastId, responseBody) {
    const {
      items: [
        {
          cdn: {
            ingestionInfo: { ingestionAddress, streamName }
          }
        }
      ]
    } = responseBody;

    this.eyeson.send({
      type: 'start_youtube',
      playerUrl: `https://youtu.be/${broadcastId}`,
      streamUrl: `${ingestionAddress}/${streamName}`
    });
  }

  // There are a couple of state changes we have to wait for and the YouTube API
  // explicitly states we should poll the API... `source` specifies the way to
  // get data, and `desiredStatus` is the status we're watiting for.
  waitForStatus(source, desiredStatus) {
    let intervalCnt = 0;
    // 5 minutes
    const maxIntervals = 150;

    return new Promise((resolve, reject) => {
      this.interval = window.setInterval(() => {
        Logger.debug('YouTubeApi::waitForStatus', desiredStatus);
        if (intervalCnt > maxIntervals) {
          this.clearInterval();
          let error = new Error('YouTube stream not ', desiredStatus);
          error.error = 'timeout';
          reject(error);
        }
        intervalCnt += 1;

        source().then(response => {
          const json = JSON.parse(response.body);
          const statusObj = json.items[0].status;
          // statusObj can be either from live stream or broadcast, and those
          // two have different status we need to look for.
          const status = statusObj.streamStatus || statusObj.lifeCycleStatus;

          this.eyeson.send({
            type: 'status_update_youtube',
            status: status,
            health: statusObj.healthStatus || {}
          });

          if (status === desiredStatus) {
            this.clearInterval();
            resolve();
          } else {
            Logger.warn('YouTubeApi::waitForStatus: ', status);
          }
        });
      }, 2000);
    });
  }

  handleError(response) {
    let error = { reason: 'account_setup' };
    try {
      this.clearInterval();
      this.cleanupBroadcast();

      error = response.error
        ? { reason: response.error }
        : JSON.parse(response.body).error.errors[0];
    } catch (err) {
      Logger.error('YouTubeApi::handleError', err);
    }

    this.eyeson.send({
      type: 'handle_youtube_error',
      error: Object.assign({}, { name: `youtube_${error.reason}` }, error)
    });
  }

  clearInterval() {
    window.clearInterval(this.interval);
  }

  // If the broadcast is either
  // - 'ready'
  // - 'created'
  // - 'testing'
  // - 'testStarting'
  // (it shows up as "upcoming" on YouTube) we delete it so we don't pollute
  // YouTube with a bunch of "upcoming" broadcasts that'll never happen.
  cleanupBroadcast() {
    if (!this.broadcastId) {
      return;
    }

    const deleteableBroadcastStates = [
      'ready',
      'created',
      'testing',
      'testStarting'
    ];

    this.listBroadcast(this.broadcastId)
      .then(response => {
        const [broadcast] = response.result.items;
        const {
          status: { lifeCycleStatus }
        } = broadcast;

        if (deleteableBroadcastStates.includes(lifeCycleStatus)) {
          this.deleteBroadcast(this.broadcastId).then(() => {
            Logger.debug('YouTubeApi::deleted: ', this.broadcastId);
          });
        } else {
          this.transitionBroadcast(this.broadcastId, 'complete').then(() => {
            Logger.debug('YouTubeApi::completed: ', this.broadcastId);
          });
        }
        this.broadcastId = null;
      })
      .catch(error => {
        Logger.error('YouTubeApi::cleanupBroadcast', error);
      });
  }
}

export default YouTubeApi;
/* eslint-enable max-lines */
