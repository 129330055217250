import { createTheme, responsiveFontSizes } from '@mui/material';
import { createBreakpoints } from '@mui/system';

const breakpoints = createBreakpoints({});

const muiTheme = createTheme({
	palette: {
		white: {
			main: '#FFF',
			contrastText: '#000'
		},
		black: {
			main: '#141418',
			light: '#202124',
			contrastText: '#FFF'
		},
		purple: {
			main: '#5756B3',
			contrastText: '#FFF'
		},
		primary: {
			main: '#3947D8'
		},
		secondary: {
			main: '#EFF8FF'
		}
	},
	typography: {
		h1: {
			fontSize: '2rem',
			fontWeight: 700,
			color: '#141418'
		}
	},
	mixins: {
		stream: {
			height: 'calc(100% - 54px)',
			[breakpoints.up('xs')]: {
				height: 'calc(100% - 48px)'
			},
			[breakpoints.up('sm')]: {
				height: 'calc(100% - 64px)'
			}
		}
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					textTransform: 'none'
				},
				sizeMedium: {
					borderRadius: 16
				}
			}
		},
		MuiIconButton: {
			defaultProps: {
				size: 'large',
				color: 'white'
			}
		},
		MuiDrawer: {
			defaultProps: {
				anchor: 'right'
			},
			styleOverrides: {
				docked: {

				}
			}
		}
	}
});

const theme = responsiveFontSizes(muiTheme);

export default theme;
