import BaseEvent from './BaseEvent.js';

class StartYouTubeEvent extends BaseEvent {
  handle(msg) {
    const { _api, _session } = this.context;

    _api.startBroadcast(msg, 'youtube').catch(error =>
      _session.emit({
        type: 'start_broadcast_error',
        platform: 'youtube',
        error: error
      })
    );
  }
}

export default StartYouTubeEvent;
