import { Box, AppBar, Toolbar, Typography, Button } from '@mui/material';
import React, { ReactElement } from 'react';
import { Outlet } from 'react-router-dom';

import { RootWrapperStyled } from './HomeLayout.styles';

const HomeLayout = (): ReactElement => {
	const handleLogout = () => {
		// TODO: add logout logic
	};

	return (
		<RootWrapperStyled>
			<Box width={1}>
				<AppBar position='static' color='secondary'>
					<Toolbar>
						<Typography variant='h6' flexGrow={1}>
							Eyson - Lionwood integration
						</Typography>
						<Button variant='contained' onClick={handleLogout}>
							Logout
						</Button>
					</Toolbar>
				</AppBar>
			</Box>
			<Outlet />
		</RootWrapperStyled>
	);
};

export default HomeLayout;
