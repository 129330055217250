import styled from '@emotion/styled';
import { Box } from '@mui/material';
interface IVideoStyledProps {
	isPreview: boolean;
}

export const RootWrapperStyled = styled(Box)<IVideoStyledProps>`
	${({ isPreview, theme }) => {
		return isPreview
			? ''
			: {
				...theme.mixins.stream
			  };
	}}
	transition-property: width;
	transition-timing-function: ${(props) => props.theme.transitions.easing.easeIn};
	transition-duration: ${(props) => props.theme.transitions.duration.standard}ms;
`;

const previewStyle = `
	height: 100%;
	max-height: 40vh;
	min-height: 19rem;
	max-width: 405px;
`;
export const VideoStyled = styled.video<IVideoStyledProps>`
	margin-top: ${(props) => props.theme.spacing(2)};
	margin-bottom: ${(props) => props.theme.spacing(2)};
	width: 100%;
	height: calc(100% - ${(props) => props.theme.spacing(4)});

	${({ isPreview }) => {
		return isPreview ? previewStyle : '';
	}}
`;
