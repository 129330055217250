import { LinearProgress } from '@mui/material';
import { SoundMeter } from 'eyeson';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import ToastMessage from '../../../../components/ToastMessage';
import { createAudioStream, stopAudioStream } from '../../../../helpers/utils';

interface ISoundLevelMeterProps {
	isAudioEnabled: boolean;
}

const SoundLevelMeter: React.FC<ISoundLevelMeterProps> = ({ isAudioEnabled }) => {
	const soundMeter = useMemo(() => new SoundMeter(), []);

	const [toastState, setToastState] = useState({ isAudio: false, toast: false, value: 0 });
	const [soundStream, setSoundStream] = useState<MediaStream | null>(null);

	const cbRef = useRef<null |(() => void)>(null);

	const handleEventOuterSound = useCallback((event: any) => {
		// listen on updates
		// event.error,  // an error occurred, default: null
		// event.value  // audio level between 0 and 100
		if (event?.value && typeof event.value === 'number') {
			setToastState((prev) => {
				if (!prev.isAudio) {
					return {
						...prev,
						toast: event.value > 30 ? true : prev.toast,
						value: 0
					};
				}
				return {
					...prev,
					value: event.value,
					toast: false
				};
			});
		}
		if (event?.warning) {
			if (event.warning === 'MicrophoneSilenceWarning') {
				setToastState((prev) => ({
					...prev,
					value: 0,
					toast: true
				}));
			}
		}
	}, []);

	useEffect(() => {
		if (toastState.toast) {
			toast(
				({ closeToast }) => {
					if (closeToast) {
						cbRef.current = closeToast;
					}
					return (
						<ToastMessage
							severity='warning'
							onClose={() => {
								setToastState((prev) => ({ ...prev, toast: false }));
								closeToast && closeToast();
							}}
							message={'You are muted'}
						/>
					);
				},
				{ autoClose: false }
			);
		}
		else {
			if (cbRef.current) {
				cbRef.current();
			}
		}
	}, [toastState.toast]);

	const getSoundStream = async () => {
		const voiceStream = await createAudioStream();

		if (voiceStream) {
			setSoundStream(voiceStream);
		}
	};

	useEffect(() => {
		getSoundStream();

		return () => {
			// stop checking the stream for audio
			soundMeter.stop();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setToastState((prev) => {
			return { ...prev, isAudio: isAudioEnabled };
		});
	}, [isAudioEnabled]);

	useEffect(() => {
		if (!soundStream) return;
		soundMeter.connectToSource(soundStream); // bind stream, listen for audio
		soundMeter.onUpdate(handleEventOuterSound);
		return () => {
			stopAudioStream(soundStream);
			setSoundStream(null);
		};
	}, [soundStream, soundMeter, handleEventOuterSound]);
	return <LinearProgress variant='determinate' value={toastState.value} />;
};

export default SoundLevelMeter;
