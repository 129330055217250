import Logger from '../Logger.js';
import MediaStreamBuilder from '../MediaStreamBuilder.js';
import BaseEvent from './BaseEvent.js';

class StartPresentingEvent extends BaseEvent {
  streamVideo() {
    const { _session } = this.context;

    const onError = error => {
      Logger.error('StartPresentingEvent', error);
      if (!error.name.match(/notallowed|permission/i)) {
        _session.emit({ type: 'capture_error', name: `error_${error.name}` });
      }
      _session.emit({ type: 'stop_presenting' });
    };

    new MediaStreamBuilder({
      audio: _session.localStream.getAudioTracks()[0].enabled,
      video: false,
      deviceMonitor: _session.deviceMonitor
    })
      .start()
      .then(userMediaStream => {
        const stream = _session.videoPlayer.invokeStream(userMediaStream);
        return this.handle({ stream });
      })
      .catch(onError);
  }

  // eslint-disable-next-line max-statements
  async handle({ stream = null }) {
    const { _session } = this.context;
    if (_session.vbgMixer) {
      _session.vbgMixer.stopOriginalStream();
      _session.vbgMixer.terminate();
    }
    if (_session.videoPlayer && !_session.videoPlayer.isStreaming()) {
      this.streamVideo();
    } else {
      let presentationStream = stream || _session.localStream;
      if (_session.canvasMixer && _session.canvasMixer.stream) {
        // eslint-disable-next-line prefer-destructuring
        presentationStream = _session.canvasMixer.stream;
      }
      await _session.setStream(presentationStream);
      _session.send({ type: 'set_presenter', on: true });
      _session.send({ type: 'desktopstreaming', on: true });
      _session.emit({ type: 'presentation_started' });
    }
  }
}

export default StartPresentingEvent;
