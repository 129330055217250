import { PropsWithChildren, ReactElement, useEffect } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';

import { AppRoutes } from './helpers/enums';
import { useAppDispatch, useAppSelector } from './helpers/hooks';
import { startupAction } from './store/actions/user';

/**
 * StartupContainer checks whether User is authenticated or not. In the later case it redirects User to login page.
 *
 * @returns {ReactElement}
 */
const StartupContainer = ({ children }: PropsWithChildren<any>): ReactElement => {
	const dispatch = useAppDispatch();
	const authenticated = useAppSelector((state) => state.user.authenticated);
	const isStartUpLoading = useAppSelector((state) => state.loading.isStartUpLoading);

	const navigate = useNavigate();
	const isLoginRoute = useMatch(AppRoutes.Login);

	useEffect(() => {
		if (authenticated && isLoginRoute && !isStartUpLoading) {
			navigate(AppRoutes.Home);
		}
	}, [authenticated, isLoginRoute, isStartUpLoading, navigate]);

	useEffect(() => {
		dispatch(startupAction());
	}, [dispatch]);

	return isStartUpLoading ? '...loading' : children;
};

export default StartupContainer;
