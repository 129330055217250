import { Box, Typography } from '@mui/material';
import React from 'react';

const ErrorPage = () => {
	return (
		<Box>
			<Typography variant='h1'>
        Some error occured!
			</Typography>
		</Box>
	);
};

export default ErrorPage;
