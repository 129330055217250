import { Videocam, Mic, VolumeUp } from '@mui/icons-material';
import { Paper, Box, Grid, Switch, MenuItem } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';
import { DeviceManager } from 'eyeson';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '../../components/Button';
import { AppRoutes } from '../../helpers/enums';
import { useAppDispatch, useAppSelector } from '../../helpers/hooks';
import {
	checkIsDeviceExist,
	getSelectedDeviceId,
	getSettingsFromLocaleStorage
} from '../../helpers/utils';
import { getRoom } from '../../store/actions/eyeson';
import { toggleAudio, toggleVideo, updateSinkId } from '../../store/slices/eyeson';
import Stream from '../CallPage/components/Stream';
import SoundLevelMeter from './components/SoundLevelMeter';
import * as Styles from './WaitingRoom.styles';

type DeviceType = 'camera' | 'microphone' | 'speaker';
type ExistingDevicesType = Record<'cameras' | 'microphones' | 'speakers', MediaDeviceInfo[]>;
type SelectedDevicesType = Record<DeviceType, string>;

const SELECTED_DEVICES_INIT: SelectedDevicesType = {
	camera: '',
	microphone: '',
	speaker: ''
};

const WaitingRoom: React.FC = () => {
	const deviceManager = useMemo(() => new DeviceManager(), []);
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const [stream, setStream] = useState<MediaStream | null>(null);
	const [devices, setDevices] = useState<ExistingDevicesType | null>(null);
	const [selectedDevices, setSelectedDevices] = useState(SELECTED_DEVICES_INIT);

	const isAudioEnabled = useAppSelector((state) => state.eyeson.isAudioEnabled);
	const isVideoEnabled = useAppSelector((state) => state.eyeson.isVideoEnabled);
	const existingAccessKey = useAppSelector((state) => state.eyeson.existingAccessKey);
	const sinkId = useAppSelector((state) => state.eyeson.sinkId);

	const isRoomConnectLoading = useAppSelector((state) => state.loading.isRoomConnectLoading);
	const isGetRoomLoading = useAppSelector((state) => state.loading.isGetRoomLoading);

	const handleCreateRoom = useCallback(() => {
		navigate(AppRoutes.Call);
	}, [navigate]);

	const handleDeviceManagerEvents = useCallback(
		(event: any) => {
			if (event?.stream && !stream) {
				setStream(event.stream as MediaStream);
			}

			if (event?.cameras && JSON.stringify(event) !== JSON.stringify(devices)) {
				setDevices(event as ExistingDevicesType);
			}
		},
		[devices, stream]
	);

	const updateDeviceSettings = useCallback(
		({ value, name }: { value: string; name: DeviceType }) => {
			if (!value || !name) {
				return;
			}
			if (name === 'microphone') {
				deviceManager.setAudioInput(value);
				dispatch(toggleAudio(true));
			}
			if (name === 'camera') {
				deviceManager.setVideoInput(value);
				dispatch(toggleVideo(true));
			}
			if (name === 'speaker') {
				deviceManager.setAudioOutput(value);
				dispatch(updateSinkId(value));
			}
		},
		[dispatch, deviceManager]
	);
	const handleUpdateActiveDevice = useCallback(
		(device: { value: string; name: DeviceType }) => {
			const { value, name } = device;
			if (!value || !name) {
				return;
			}
			updateDeviceSettings(device);
			setSelectedDevices((prev) => ({ ...prev, [name]: value }));
			deviceManager.storeConstraints();
		},
		[deviceManager, updateDeviceSettings]
	);

	useEffect(() => {
		if (!stream || !devices) return;
		const { audioId, sinkId, videoId } = getSettingsFromLocaleStorage();
		const camera = checkIsDeviceExist(videoId, devices.cameras)
			? videoId
			: getSelectedDeviceId(stream, 'Video');
		const microphone = checkIsDeviceExist(audioId, devices.microphones)
			? audioId
			: getSelectedDeviceId(stream, 'Audio');
		const speaker = checkIsDeviceExist(sinkId, devices.speakers)
			? sinkId
			: devices.speakers[0]?.deviceId;

		if (speaker) updateDeviceSettings({ value: speaker, name: 'speaker' });

		setSelectedDevices((prev) => ({
			...prev,
			camera: camera ?? '',
			microphone: microphone ?? '',
			speaker: speaker ?? prev.speaker
		}));
	}, [stream, devices, updateDeviceSettings]);

	useEffect(() => {
		dispatch(getRoom(existingAccessKey || undefined));
		deviceManager.onChange(handleDeviceManagerEvents);
		deviceManager.start();
		return () => {
			deviceManager.storeConstraints();
			deviceManager.terminate();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleToggleAudio = useCallback(() => {
		deviceManager.updateWithOptions({ audio: !isAudioEnabled, eco: false, video: isVideoEnabled });
		dispatch(toggleAudio(!isAudioEnabled));
	}, [dispatch, isAudioEnabled, isVideoEnabled, deviceManager]);

	const handleToggleVideo = useCallback(() => {
		deviceManager.updateWithOptions({ audio: isAudioEnabled, eco: false, video: !isVideoEnabled });
		dispatch(toggleVideo(!isVideoEnabled));
	}, [dispatch, isVideoEnabled, isAudioEnabled, deviceManager]);

	const onDeviceChange = async (event: SelectChangeEvent<unknown>) => {
		if (!event.target?.value) {
			return;
		}
		const { value } = event.target as SelectChangeEvent<string>['target'];
		const name = event.target.name as DeviceType;
		handleUpdateActiveDevice({ value, name });
	};

	return (
		<Styles.RootWrapperStyled>
			<Paper>
				<Grid container spacing={5} flexDirection='column' padding={4}>
					<Grid item>
						<Styles.VideoWrapper>
							{isVideoEnabled ? <Stream src={stream} width={'100%'} isPreview sinkId={sinkId} muted /> : <img src='/no-video.png' alt='video off'/>}
						</Styles.VideoWrapper>
					</Grid>
					<Grid item>
						<SoundLevelMeter isAudioEnabled={isAudioEnabled} />
					</Grid>
					<Grid item>
						<Grid container spacing={1} flexDirection='row' flexGrow={1}>
							<Grid item flexGrow={1}>
								<Box display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
									<Box py={1}>
										<Styles.Select
											labelId='video-input'
											value={selectedDevices.camera}
											label='video source'
											onChange={onDeviceChange}
											size='small'
											autoWidth
											name='camera'
										>
											{devices?.cameras.map((item) => (
												<MenuItem key={item.deviceId} value={item.deviceId}>
													{item.label}
												</MenuItem>
											))}
										</Styles.Select>
									</Box>
									<Videocam />
									<Switch onChange={handleToggleVideo} checked={isVideoEnabled} />
								</Box>
							</Grid>
							<Grid item flexGrow={1}>
								<Box display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
									<Box py={1}>
										<Styles.Select
											labelId='mic-input'
											value={selectedDevices.microphone}
											label='mic source'
											onChange={onDeviceChange}
											size='small'
											autoWidth
											name='microphone'
										>
											{devices?.microphones.map((item) => (
												<MenuItem key={item.deviceId} value={item.deviceId}>
													{item.label}
												</MenuItem>
											))}
										</Styles.Select>
									</Box>
									<Mic />
									<Switch onChange={handleToggleAudio} checked={isAudioEnabled} />
								</Box>
							</Grid>
							<Grid item flexGrow={1}>
								<Box display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
									<Box py={1}>
										<Styles.Select
											labelId='speaker'
											value={selectedDevices.speaker}
											label='speaker'
											onChange={onDeviceChange}
											size='small'
											name='speaker'
											id='speaker'
										>
											{devices?.speakers.map((item) => (
												<MenuItem key={item.deviceId} value={item.deviceId}>
													{item.label}
												</MenuItem>
											))}
										</Styles.Select>
									</Box>
									<VolumeUp />
								</Box>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Paper>
			<Box padding={2}>
				<Button
					isLoading={isRoomConnectLoading || isGetRoomLoading}
					onClick={handleCreateRoom}
					fullWidth
					variant='contained'
				>
					{!isRoomConnectLoading && !isGetRoomLoading && 'Join'}
				</Button>
			</Box>
		</Styles.RootWrapperStyled>
	);
};

export default WaitingRoom;
