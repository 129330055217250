/* eslint-disable no-console */
import {
	Grid,
	Card,
	CardContent,
	TextField,
	Typography
} from '@mui/material';
import React, { ChangeEvent, KeyboardEvent, useCallback, useState } from 'react';

import Button from '../../components/Button';
import { useAppDispatch, useAppSelector } from '../../helpers/hooks';
import { loginAction, registerAction } from '../../store/actions/user';
import { CardActionsStyled } from './LoginPage.styles';

const LoginPage = () => {
	const dispatch = useAppDispatch();
	const isAuthLoading = useAppSelector((state) => state.loading.isAuthLoading);

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');

	const handleChangeEmail = useCallback((event: ChangeEvent<HTMLInputElement>) => {
		setEmail(event.target.value);
	}, []);

	const handleChangePassword = useCallback((event: ChangeEvent<HTMLInputElement>) => {
		setPassword(event.target.value);
	}, []);

	const handleLogin = useCallback(() => {
		dispatch(loginAction({ email, password }));
	}, [dispatch, email, password]);

	const handleRegister = useCallback(() => {
		dispatch(registerAction({ email, password }));
	}, [dispatch, email, password]);

	const handleKeyBoardDown = useCallback((event: KeyboardEvent<HTMLDivElement>) => {
		if (event.code === 'Enter') {
			handleLogin();
		}
	}, [handleLogin]);

	return (
		<Card>
			<CardContent>
				<Typography my={3} variant='h1' color='black.light' textAlign='center'>
						Log in or signup
				</Typography>
				<Grid container spacing={2} alignItems='center'>
					<Grid item xs={4}>
						<Typography color='black.light'>Email</Typography>
					</Grid>
					<Grid item xs={8}>
						<TextField
							id='email'
							label='Email'
							value={email}
							onChange={handleChangeEmail}
							disabled={isAuthLoading}
							fullWidth
						/>
					</Grid>
					<Grid item xs={4}>
						<Typography color='black.light'>Password</Typography>
					</Grid>
					<Grid item xs={8}>
						<TextField
							id='password'
							label='Password'
							type='password'
							value={password}
							onChange={handleChangePassword}
							onKeyDown={handleKeyBoardDown}
							disabled={isAuthLoading}
							fullWidth
						/>
					</Grid>
				</Grid>
			</CardContent>
			<CardActionsStyled>
				<Button variant='contained' onClick={handleRegister} isLoading={isAuthLoading}>
					Register
				</Button>
				<Button
					variant='contained'
					onClick={handleLogin}
					isLoading={isAuthLoading}
				>
					Login
				</Button>
			</CardActionsStyled>
		</Card>
	);
};

export default LoginPage;
