import { NamedExoticComponent, FunctionComponent } from 'react';
import { RouteProps } from 'react-router-dom';

import { AppRoutes } from '../helpers/enums';
import CallPage from '../pages/CallPage';
import ErrorPage from '../pages/ErrorPage';
import HomePage from '../pages/HomePage';
import LoginPage from '../pages/LoginPage';
import WaitingRoom from '../pages/WaitingRoom';
import DefaultLayout from './components/DefaultLayout';
import HomeLayout from './components/HomeLayout';

interface BaseRoute extends Pick<RouteProps, 'path' | 'index'> {
	component: NamedExoticComponent | FunctionComponent;
	name: string;
	isProtected?: boolean;
}

interface Route {
	layout: FunctionComponent;
	name: string;
	routes: BaseRoute[];
}

/**
 * App routes with layouts and corresponding pages
 */
const routes: Route[] = [
	{
		layout: HomeLayout,
		name: 'homeLayout',
		routes: [
			{
				path: AppRoutes.Home,
				component: HomePage,
				name: 'HomePage',
				index: true,
				isProtected: true
			},
			{
				path: AppRoutes.WaitingRoom,
				component: WaitingRoom,
				name: 'WaitingRoom',
				index: true,
				isProtected: false
			}
		]
	},
	{
		layout: DefaultLayout,
		name: 'defaultLayout',
		routes: [
			{
				path: AppRoutes.Login,
				name: 'LoginPage',
				component: LoginPage
			},
			{
				path: AppRoutes.Call,
				name: 'CallPage',
				component: CallPage
			},
			{
				path: AppRoutes.Error,
				name: 'ErrorPage',
				component: ErrorPage
			}
		]
	}
];

export default routes;
