import Axios, { AxiosRequestConfig, AxiosError } from 'axios';

const BASE_URL = process.env.NODE_ENV === 'production'
	? 'https://api-eyeson.lionwood.software'
	: 'http://localhost:8000';

export const AXIOS_INSTANCE = Axios.create({
	baseURL: BASE_URL,
	withCredentials: true,
	headers: {
		'Access-Control-Allow-Origin': '*'
	}
});

export const customInstance = <T>(
	config: AxiosRequestConfig,
	options?: AxiosRequestConfig
): Promise<T> => {
	const source = Axios.CancelToken.source();
	const promise = AXIOS_INSTANCE({
		...config,
		...options,
		cancelToken: source.token
	}).then(({ data }) => data);

	// @ts-ignore
	promise.cancel = () => {
		source.cancel('Query was cancelled');
	};

	return promise;
};

export type ErrorType<Error> = AxiosError<Error>;
