import FeatureDetector from './FeatureDetector.js';

/**
 * Add 'Visoweb' identifier to origin field of sdp. Believe this is still
 * relevant for some remotes.
 **/
const addOriginToSDP = sdp => {
  return sdp.replace('o=-', 'o=Visoweb');
};

/**
 * Add session attribute to disable sfu mode from the client.
 **/
// eslint-disable-next-line max-statements
const addSessionAttributes = sdp => {
  // double check config.allowSafariSFU on runtime
  const splitSDP = sdp.split('\r\n');
  const tLineIdx = splitSDP.findIndex(line => line.startsWith('t='));
  let addIndex = 1;
  if (FeatureDetector.canSFU()) {
    // insert sfuLine after tLine (timing), delete 0 elements
    splitSDP.splice(tLineIdx + addIndex, 0, 'a=sfu-capable');
    addIndex++;
    if (FeatureDetector.disallowAudioSyncSrcChange()) {
      splitSDP.splice(tLineIdx + addIndex, 0, 'a=disallow-audio-ssrc-change');
      addIndex++;
    }
  }
  if (FeatureDetector.canDataChannel()) {
    splitSDP.splice(tLineIdx + addIndex, 0, 'a=eyeson-datachan-capable');
    addIndex++;
    splitSDP.splice(tLineIdx + addIndex, 0, 'a=eyeson-datachan-keepalive');
  }
  return splitSDP.join('\r\n');
};

const sendOnly = sdp => {
  return sdp.replace(/a=sendrecv/g, 'a=sendonly');
};

const getModifiers = options => {
  const modifiers = [addOriginToSDP, addSessionAttributes];
  if (options.sendOnly === true) {
    modifiers.push(sendOnly);
  }
  return modifiers;
};

export { getModifiers };
