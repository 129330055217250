export const getSelectedDeviceId = (stream: MediaStream, type: 'Audio' | 'Video') => {
	try {
		const tracks = stream[`get${type}Tracks`]();
		if (tracks.length > 0) {
			const settings = tracks[0].getSettings();
			return settings.deviceId;
		}
	}
	catch (error) {
		console.error(error);
	}
	return '';
};

export interface IDeviceOptions {
	value: string;
	label: string;
}
export const mapDeviceList = (device: MediaDeviceInfo): IDeviceOptions => ({
	value: device.deviceId,
	label: device.label
});

export const getSettingsFromLocaleStorage = () => {
	const audioId = localStorage.getItem('eyeson.audioId');
	const videoId = localStorage.getItem('eyeson.videoId');
	const sinkId = localStorage.getItem('eyeson.sinkId');

	return {
		audioId: audioId ? JSON.parse(audioId) : null,
		videoId: videoId ? JSON.parse(videoId) : null,
		sinkId: sinkId ? JSON.parse(sinkId) : null
	};
};

export const checkIsDeviceExist = (id: string | null, devices: MediaDeviceInfo[]) => {
	if (!id) return false;
	return !!devices.find((device) => device.deviceId === id);
};

export const createAudioStream = () => {
	const promise = new Promise<void>((resolve, reject) => {
		if (navigator.mediaDevices && !!navigator.mediaDevices.getUserMedia) {
			return resolve();
		}
		else {
			reject(new Error('getUserMedia not supported on your browser!'));
		}
	});
	return (
		promise
			.then(() => {
				// constraints - only audio needed for this app
				return navigator.mediaDevices.getUserMedia({
					audio: true
				});
			})
			// Success callback
			.then((stream) => {
				return stream;
			})
			// Error callback
			.catch((err) => {
				console.error(`The following getUserMedia error occurred: ${err}`);
			})
	);
};

export const stopAudioStream = (stream: MediaStream | null) => {
	stream?.getTracks().forEach((track) => {
		if (track.readyState === 'live' && track.kind === 'audio') {
			track.stop();
		}
	});
};
