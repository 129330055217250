import { ArrowForwardIos } from '@mui/icons-material';
import { Drawer, DrawerProps, IconButton } from '@mui/material';
import { CSSProperties } from '@mui/styled-engine';
import React, { ReactElement } from 'react';

import { DrawerContentStyled, DraweContentHeadStyled } from './PersistentDrawer.styles';

export interface PersistentDrawerProps extends Omit<DrawerProps, 'variant' | 'elevation' | 'PaperProps' | 'onClose'> {
	width?: CSSProperties['width'];
	height?: CSSProperties['height'];
	onClose: () => void;
}

/**
 * Component to display a side modal.
 * As additional props it accepts custom `width` and `height`.
 * But the main use case is a `CallPage` page, where height should be aligned with a `Stream` component.
 * That's why default height is coming from `theme.mixins.stream` object.
 *
 * @param {PersistentDrawerProps}
 * @returns {ReactElement}
 */
const PersistentDrawer = ({ width, height, children, onClose, ...props }: PersistentDrawerProps): ReactElement => {
	return (
		<Drawer
			PaperProps={{
				sx: (theme) => ({
					backgroundColor: 'transparent',
					width,
					...(height ? { height } : theme.mixins.stream)
				})
			}}
			variant='persistent'
			elevation={0}
			{...props}
		>
			<DrawerContentStyled>
				<DraweContentHeadStyled>
					<IconButton
						aria-label='closeDrawer'
						color='black'
						onClick={onClose}
					>
						<ArrowForwardIos />
					</IconButton>
				</DraweContentHeadStyled>
				{children}
			</DrawerContentStyled>
		</Drawer>
	);
};

export default PersistentDrawer;
