import { LayoutWithKey } from '../../api';
import { RoomLayouts, RoomCustomLayoutMarks, RoomCustomLayoutAspectRatio } from '../enums';

/**
 * Helper function that sets auto layout parameters.
 * @param users string[]
 * @returns {Omit<LayoutWithKey, 'accessKey'>}
 */
const getAutoLayoutProps = (users: string[]): Omit<LayoutWithKey, 'accessKey'> => ({
	layout: 'auto',
	users
});

/**
 * Helper function that arrange an available list of users in the way to display a circle layout.
 * @param users string[]
 * @returns {Omit<LayoutWithKey, 'accessKey'>}
 */
const getCircleLayoutProps = (users: string[], aspectRatio = RoomCustomLayoutAspectRatio.Medium): Omit<LayoutWithKey, 'accessKey'> => {
	if (users.length < 5) return getAutoLayoutProps(users);
	const circle = [];
	let currentIndex = 0;

	const xSideLength = parseInt(aspectRatio.split(':')[0]);
	const ySideLength = parseInt(aspectRatio.split(':')[1]);

	for (let i = 0; i < xSideLength; i++) {
		for (let j = 0; j < ySideLength; j++) {
			if ((i === 0 || i === xSideLength - 1 || j === 0 || j === ySideLength - 1) && currentIndex < users.length) {
				circle.push(users[currentIndex]);
				currentIndex++;
			}
			else {
				circle.push('');
			}
		}
	}

	return {
		layout: 'custom',
		users: circle
	};
};

/**
 * Helper function that arrange an available list of users in the way to display a cross layout.
 * @param users string[]
 * @returns {Omit<LayoutWithKey, 'accessKey'>}
 */
const getCrossLayoutProps = (users: string[], aspectRatio = RoomCustomLayoutAspectRatio.Medium): Omit<LayoutWithKey, 'accessKey'> => {
	if (users.length < 5) return getAutoLayoutProps(users);
	const cross = [];
	let currentIndex = 0;

	const xSideLength = parseInt(aspectRatio.split(':')[0]);
	const ySideLength = parseInt(aspectRatio.split(':')[1]);

	for (let i = 0; i < xSideLength; i++) {
		for (let j = 0; j < ySideLength; j++) {
			if ((i === j || j === xSideLength - i - 1 || i === ySideLength - j - 1) && currentIndex < users.length) {
				cross.push(users[currentIndex]);
				currentIndex++;
			}
			else {
				cross.push('');
			}
		}
	}

	return {
		layout: 'custom',
		users: cross
	};
};

const mapper = {
	[RoomLayouts.Auto]: getAutoLayoutProps,
	[RoomLayouts.Circle]: getCircleLayoutProps,
	[RoomLayouts.Cross]: getCrossLayoutProps
};

const layoutAspectRatioValues = {
	[RoomCustomLayoutMarks.Small]: RoomCustomLayoutAspectRatio.Small,
	[RoomCustomLayoutMarks.Medium]: RoomCustomLayoutAspectRatio.Medium,
	[RoomCustomLayoutMarks.Large]: RoomCustomLayoutAspectRatio.Large,
	[RoomCustomLayoutMarks.ExtraLarge]: RoomCustomLayoutAspectRatio.ExtraLarge
};

export const getChangeLayoutProps = (accessKey: string, layout: RoomLayouts, layoutAspectRatio: RoomCustomLayoutMarks, users: string[]): LayoutWithKey => {
	const aspectRatio = layoutAspectRatioValues[layoutAspectRatio];
	const layoutProps = mapper[layout](users, aspectRatio);

	return {
		accessKey,
		...layoutProps
	};
};
