import { AxiosError } from 'axios';
import { SagaIterator } from 'redux-saga';
import { call, select, takeEvery } from 'redux-saga/effects';

import { RootState } from '../..';
import { changeLayout } from '../../../api';
import { EYESON_CHANGE_ROOM_LAYOUT } from '../../../helpers/constants';
import { getChangeLayoutProps } from '../../../helpers/services/layouts';
import { toastError } from '../../../helpers/services/toasts';

function* makeFetch(): SagaIterator {
	try {
		const accessKey = yield select((state: RootState) => state.eyeson.accessKey);
		const userIds = yield select((state: RootState) => state.eyeson.users);
		const layout = yield select((state: RootState) => state.eyeson.layout);
		const layoutAspectRatio = yield select((state: RootState) => state.eyeson.layoutAspectRatio);

		const layoutProps = yield call(getChangeLayoutProps, accessKey, layout, layoutAspectRatio, userIds);

		yield call(changeLayout, layoutProps);
	}
	catch (error) {
		if (error instanceof AxiosError && error.response?.data?.detail) {
			yield call(toastError, error.response.data.detail);
		}
	}
};

export function* watch() {
	yield takeEvery(EYESON_CHANGE_ROOM_LAYOUT, makeFetch);
};
