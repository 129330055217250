import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const RootWrapperStyled = styled(Box)`
  display: flex;
  flex-direction: row;
  column-gap: ${props => props.theme.spacing(2)};
  width: 90%;
  margin: ${props => props.theme.spacing(8)} auto 0;
`;
