import Logger from '../Logger.js';
import BaseEvent from './BaseEvent.js';
import MediaStreamBuilder from '../MediaStreamBuilder.js';
import { getScreenPresentationTracks } from '../utils/StreamHelpers.js';
import MicMixer from '../MicMixer.js';

class ScreenCaptureEvent extends BaseEvent {
  handle({ audio, screen = false, screenStream }) {
    const { _session } = this.context;
    if (_session.micMixer) {
      _session.micMixer.destroy();
    }
    _session.micMixer = new MicMixer();

    const onError = error => {
      Logger.error('ScreenCaptureEvent', error);
      if (!error.name.match(/notallowed|permission/i) && screen) {
        let name = `error_Screen_${error.name}`;
        if (error.message.indexOf('not start audio') > -1) {
          name = `error_Screen_${error.name}_Audio`;
        }
        _session.emit({ type: 'capture_error', name });
      }
      _session.emit({ type: 'stop_presenting' });
    };

    new MediaStreamBuilder({
      audio: audio,
      video: false,
      screen: screen,
      screenStream: screenStream,
      existingStream: _session.localStream,
      micMixer: _session.micMixer,
      isPresentation: true,
      deviceMonitor: _session.deviceMonitor
    })
      .start()
      .then(stream => {
        // cleanup when screen capture is stopped via chrome's built-in/native
        // "stop sharing" button (a blue button on bottom of screen).
        const [screenTrack] = getScreenPresentationTracks(stream);
        if (screenTrack) {
          screenTrack.addEventListener('ended', () => {
            _session.emit({ type: 'stop_presenting' });
          });
        }
        return stream;
      })
      .then(stream => {
        _session.emit({ type: 'start_presenting', stream: stream });
      })
      .catch(onError);
  }
}

export default ScreenCaptureEvent;
