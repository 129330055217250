import FeatureDetector from './FeatureDetector.js';
import Logger from './Logger.js';
import { stopStream } from './utils/StreamHelpers.js';

const videoFrameSupport =
  typeof HTMLVideoElement.prototype.requestVideoFrameCallback === 'function';
const isIOSDevice = FeatureDetector.isIOSDevice();

class VideoPlayer {
  // eslint-disable-next-line max-statements
  constructor({
    canvas,
    file,
    onReady,
    onDuration,
    onTimeUpdate,
    onEnd,
    performAction
  }) {
    this.ac = null;
    this.acOut = null;
    this.gainNode = null;
    this.timer = null;
    this.canvas = canvas;
    this.url = URL.createObjectURL(file);
    this.ctx = canvas.getContext('2d');
    this.video = document.createElement('video');
    this.callback = { onReady, onDuration, onTimeUpdate, onEnd, performAction };
    this.errorCallback = null;
    this.boundDrawVideoFrame = this.drawVideoFrame.bind(this);
    this.boundInitCanvasStream = this.initCanvasStream.bind(this);
    this.isPlaying = false;
    this.blockTimeUpdate = false;
    this.micOnlyStream = null;
    this.userMediaStream = null;
    this.initTimeout = null;
    this.invokationTimer = null;
    this.initVideo();
  }

  drawVideoFrame() {
    this.ctx.drawImage(this.video, 0, 0);
    if (videoFrameSupport) {
      this.timer = this.video.requestVideoFrameCallback(
        this.boundDrawVideoFrame
      );
    } else {
      this.timer = requestAnimationFrame(this.boundDrawVideoFrame);
    }
  }

  // eslint-disable-next-line max-statements
  initVideo() {
    const { video } = this;
    video.playsInline = true;
    video.autoplay = Boolean(isIOSDevice);
    video.preload = '';
    video.onplaying = this.onVideoPlay.bind(this);
    video.onpause = this.onVideoPause.bind(this);
    video.onseeked = this.onVideoSeeked.bind(this);
    video.ontimeupdate = this.onVideoTimeUpdate.bind(this);
    video.onloadeddata = this.onVideoReady.bind(this);
    video.onerror = this.onVideoError.bind(this);
    video.onended = this.onVideoEnd.bind(this);
    this.initTimeout = setTimeout(() => this.onVideoError(), 6000);
    video.src = this.url;
  }

  // eslint-disable-next-line max-statements
  onVideoReady() {
    const { video, canvas } = this;
    clearTimeout(this.initTimeout);
    this.callback.onDuration(video.duration);
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    this.ac = new (window.AudioContext || window.webkitAudioContext)();
    this.gainNode = this.ac.createGain();
    this.ac.createMediaElementSource(video).connect(this.gainNode);
    this.gainNode.connect(this.ac.destination);
    if (videoFrameSupport) {
      this.timer = video.requestVideoFrameCallback(this.boundDrawVideoFrame);
    }
    this.callback.performAction(this.onVideoAction.bind(this));
    try {
      this.ctx.drawImage(video, 0, 0);
    } catch (error) {
      if (this.errorCallback) {
        this.errorCallback(error);
      }
      return;
    }
    this.callback.onReady();
    if (video.autoplay) {
      setTimeout(() => {
        video.pause();
        video.currentTime = 0;
      });
    }
  }

  onVideoAction(type, value) {
    if (type === 'play') {
      return this.onPlayAction();
    } else if (type === 'pause') {
      return this.onPauseAction();
    } else if (type === 'time') {
      this.onTimeAction(value);
    } else if (type === 'volume') {
      this.onVolumeAction(value);
    }
    return Promise.resolve();
  }

  onPlayAction() {
    const { video } = this;
    if (video.paused && !this.isPlaying) {
      return video.play().then(
        () => video.paused,
        () => video.paused
      );
    }
    return Promise.resolve(video.paused);
  }

  onPauseAction() {
    const { video } = this;
    if (!video.paused && this.isPlaying) {
      video.pause();
    }
    return Promise.resolve(video.paused);
  }

  onTimeAction(time) {
    const { video } = this;
    this.blockTimeUpdate = true;
    if (time >= video.duration) {
      video.currentTime = video.duration - 0.1;
    } else {
      video.currentTime = time;
    }
    this.blockTimeUpdate = false;
  }

  onVolumeAction(volume) {
    if (this.gainNode) {
      this.gainNode.gain.value = volume;
    }
  }

  onVideoPlay() {
    this.isPlaying = true;
    if (!videoFrameSupport) {
      this.timer = requestAnimationFrame(this.boundDrawVideoFrame);
    }
    if (this.invokationTimer) {
      cancelAnimationFrame(this.invokationTimer);
      this.invokationTimer = null;
    }
  }

  onVideoPause() {
    // also triggered on onended
    this.isPlaying = false;
    if (!videoFrameSupport && this.timer) {
      cancelAnimationFrame(this.timer);
    }
  }

  onVideoSeeked() {
    if (!videoFrameSupport && !this.isPlaying) {
      this.ctx.drawImage(this.video, 0, 0);
    }
  }

  onVideoTimeUpdate() {
    if (!this.blockTimeUpdate) {
      this.callback.onTimeUpdate(this.video.currentTime);
    }
  }

  onVideoError() {
    const { video } = this;
    const error =
      video && video.error ? video.error : new TypeError('VideoPlayer failed');
    Logger.error('VideoPlayer failed', error);
    if (this.errorCallback) {
      this.errorCallback(error);
    }
  }

  onVideoEnd() {
    this.callback.onEnd();
  }

  initCanvasStream() {
    this.ctx.drawImage(this.video, 0, 0);
    this.invokationTimer = requestAnimationFrame(this.boundInitCanvasStream);
  }

  // eslint-disable-next-line max-statements
  invokeStream(userMediaStream) {
    const canvasStream = this.canvas.captureStream(15);
    const [canvasStreamTrack] = canvasStream.getVideoTracks();
    // Firefox issue, track is no CanvasCaptureMediaStreamTrack
    if (!canvasStreamTrack.canvas) {
      canvasStreamTrack.type = 'canvas-track';
      canvasStreamTrack.canvas = this.canvas;
    }
    const outStream = new MediaStream([canvasStreamTrack]);
    this.acOut = this.ac.createMediaStreamDestination();
    this.gainNode.connect(this.acOut);
    this.ac.createMediaStreamSource(userMediaStream).connect(this.acOut);
    this.acOut.stream
      .getAudioTracks()
      .forEach(track => outStream.addTrack(track));
    this.userMediaStream = userMediaStream;
    this.micOnlyStream = new MediaStream(
      userMediaStream.getAudioTracks().concat(canvasStreamTrack)
    );
    if (!this.isPlaying) {
      this.invokationTimer = requestAnimationFrame(this.boundInitCanvasStream);
    }
    return outStream;
  }

  isStreaming() {
    return Boolean(this.micOnlyStream);
  }

  getMicOnlyStream() {
    return this.micOnlyStream;
  }

  onError(callback) {
    this.errorCallback = callback;
  }

  // eslint-disable-next-line max-statements
  destroy() {
    const { video } = this;
    clearTimeout(this.initTimeout);
    if (this.url) {
      URL.revokeObjectURL(this.url);
      this.url = null;
    }
    if (this.timer) {
      if (videoFrameSupport) {
        video.cancelVideoFrameCallback(this.timer);
      } else {
        cancelAnimationFrame(this.timer);
      }
      this.timer = null;
    }
    if (this.invokationTimer) {
      cancelAnimationFrame(this.invokationTimer);
      this.invokationTimer = null;
    }
    if (this.userMediaStream) {
      stopStream(this.userMediaStream);
      this.userMediaStream = null;
    }
    if (this.micOnlyStream) {
      stopStream(this.micOnlyStream);
      this.micOnlyStream = null;
    }
    if (this.acOut) {
      stopStream(this.acOut.stream);
      this.acOut = null;
    }
    if (this.ac) {
      this.gainNode.disconnect();
      this.ac.close().then(() => {
        this.ac = null;
        this.gainNode = null;
      });
    }
    if (video) {
      video.pause();
      video.onplaying = null;
      video.onpause = null;
      video.ontimeupdate = null;
      video.onloadeddata = null;
      video.onseeked = null;
      video.onerror = null;
      video.onended = null;
      this.video = null;
    }
    this.callback = null;
    this.errorCallback = null;
    this.ctx = null;
    this.canvas = null;
    this.boundDrawVideoFrame = null;
  }
}

export default VideoPlayer;
