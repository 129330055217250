/* eslint-disable max-lines */
import config from './config.js';
import ComApi from './ComApi.js';
import Logger from './Logger.js';
import throttle from './utils/throttle.js';
import EventHandler from './EventHandler.js';
import ConferenceSession from './ConferenceSession.js';
import ConnectionMonitor from './ConnectionMonitor.js';
import ActionCableConnection from './ActionCableConnection.js';

/**
 * eyeson lib core.
 **/
let core = { eventHandler: new EventHandler() };

/**
 * Initial connection status change updater.
 **/
const updateStatus = status =>
  core.eventHandler.send({ type: 'connection', connectionStatus: status });

const keepRoomAlive = () => {
  core.keepRoomAlive = setInterval(() => {
    core.rtConnection.send({ message: 'user_joins' });
  }, 30000);
};

/**
 * Remove this once WSS messages arrive reliably.
 **/
const pollingFallback = () => {
  let counter = 1;
  core.pollingFallbackInterval = setInterval(() => {
    if (counter === 200) {
      Logger.debug(
        'eyeson::pollingFallback: max count exceeded, clearing interval.'
      );
      clearInterval(core.pollingFallbackInterval);
      return;
    }

    if (core.eventHandler._connection) {
      Logger.debug(
        'eyeson::pollingFallback: connection set, clearing interval.'
      );
      clearInterval(core.pollingFallbackInterval);
      return;
    }

    core.comApi.getRoom(data => {
      if (data.ready === true) {
        Logger.debug('eyeson::pollingFallback: room ready');
        core.eventHandler.send({ type: 'room_ready', content: data });
        return;
      }
      Logger.debug('eyeson::pollingFallback: room not ready', counter);
      counter += 1;
    });
  }, 5000);
};

/**
 * Load initial room data.
 **/
const loadInitialInfos = data => {
  if (data.broadcasts) {
    core.eventHandler.send({
      type: 'broadcasts_update',
      broadcasts: data.broadcasts
    });
  }
};

/**
 * Join a session and listen to any events. eventHandler keeps all the
 * stuff.
 **/
const joinSession = function (mediaOptions) {
  if (!core.eventHandler._connection) {
    Logger.error(
      'You tried to join a session that is not yet available. ' +
        'Before calling join, a connection status of connected has ' +
        'to be received.'
    );
    return;
  }

  const session = new ConferenceSession(
    core.eventHandler._connection,
    core.comApi,
    mediaOptions
  );
  session.setMonitor(core.eventHandler.monitor);
  core.eventHandler.session = session;

  session.start();
  loadInitialInfos(core.eventHandler._rtData);
  clearInterval(core.keepRoomAlive);
  this.session = session; // eslint-disable-line no-invalid-this
};

/**
 * Initialise our connections.
 **/
/* eslint-disable max-statements */
const prepareConnection = function (eyeson) {
  updateStatus('fetch_room');

  core.eventHandler.eyeson = eyeson;

  core.comApi.onError = () =>
    core.eventHandler.send({ type: 'warning', name: 'error:comapi' });

  core.comApi.getRoom(data => {
    if (data.error) {
      Logger.warn('eyeson::prepareConnection', data.error);
      updateStatus('access_denied');
      return;
    }
    updateStatus('received_room');

    core.rtConnection = new ActionCableConnection(data.links.websocket);
    core.eventHandler.rtConnection = core.rtConnection;
    core.rtConnection.startSession();

    core.eventHandler.monitor = new ConnectionMonitor();
    core.eventHandler.api = core.comApi;
    keepRoomAlive();
    pollingFallback();
  });
};
/* eslint-enable max-statements */

/****** The following represents the public API, adapt with caution! *********/
export default {
  config: config,
  core: core,

  /****** Public data ********************************************************/

  /**
   * The room, user and links to be updated when fetched from the ComAPI.
   **/
  room: {},
  user: {},
  links: {},

  /****** Public helper methods **********************************************/

  /**
   * Attach event listener
   **/
  onEvent: listener => {
    if (typeof listener !== 'function') {
      Logger.error(
        'A listener to eyeson events has to be of type function.' +
          ' The argument passed to onEvent is of type ' +
          typeof listener +
          '.'
      );
      return;
    }
    core.eventHandler.onReceive(listener);
  },

  /**
   * Remove event listener
   **/
  offEvent: listener => core.eventHandler.removeListener(listener),

  /**
   * Prepare required core connections.
   **/
  connect: function (token) {
    Logger.debug('eyeson::connect', token);
    core.comApi = new ComApi(this.config.api, token);
    prepareConnection(this);
  },

  /**
   * Join a session with supplied mediaOptions (audio/video).
   **/
  join: function (mediaOptions) {
    Logger.debug('eyeson::join', mediaOptions);
    joinSession.bind(this, mediaOptions)();
  },

  /**
   * Start an eyeson room meeting.
   **/
  start: function (token, mediaOptions = { audio: true, video: true }) {
    Logger.debug('eyeson::start');
    const joinOnConnect = event => {
      if (event.connectionStatus !== 'ready') {
        return;
      }
      this.offEvent(joinOnConnect);
      this.join(mediaOptions);
    };
    this.onEvent(joinOnConnect);
    this.connect(token);
  },

  /**
   * Destroy and cleanup a session.
   **/
  destroy: function () {
    Logger.debug('eyeson::destroy');
    clearInterval(core.keepRoomAlive);
    core.eventHandler.destroy();
    core.eventHandler = new EventHandler();
  },

  /**
   * Receive an event from client.
   **/
  send: function (msg) {
    msg._src = 'client';
    return core.eventHandler.send(msg);
  },

  /**
   * When invoked repeatedly, will only actually call the original function at
   * most once per every wait milliseconds.
   **/
  throttledSend: function (msg) {
    if (!this._throttledSend) {
      this._throttledSend = throttle(message => this.send(message), 500);
    }

    return this._throttledSend(msg);
  }
};
/* eslint-enable max-lines */
