import { ContentCopy } from '@mui/icons-material';
import { IconButton, InputAdornment, OutlinedInput } from '@mui/material';
import React, { ReactElement } from 'react';

import { toastError, toastSuccess } from '../../helpers/services/toasts';

interface CopyToClipboardProps {
	value: string;
}

/**
 * Component to display some text and interface to copy to clipboard this text.
 *
 * @param {CopyToClipboardProps}
 * @returns {ReactElement}
 */
const CopyToClipboard = ({ value }: CopyToClipboardProps): ReactElement => {
	const handleClick = () => {
		navigator.clipboard.writeText(value)
			.then(() => {
				toastSuccess('Copied', { autoClose: 1000 });
			})
			.catch(() => {
				toastError('Copy failed');
			});
	};

	return (
		<OutlinedInput
			value={value}
			endAdornment={
				<InputAdornment position='end'>
					<IconButton
						aria-label='copyToClipboard'
						onClick={handleClick}
						edge='end'
						color='default'
					>
						<ContentCopy />
					</IconButton>
				</InputAdornment>
			}
			fullWidth
			disabled
		/>
	);
};

export default CopyToClipboard;
