/* eslint-disable no-unused-vars */

/**
 * App routes.
 */
export enum AppRoutes {
	Home = '/',
	Login = '/login',
	Call = '/call',
	Error = '/error',
	WaitingRoom = '/waiting-room'
}

/**
 * Event types that may occur during eyeson connection.
 * @see https://eyeson-team.github.io/js-docs/events/
 */
export enum EyesonEventType {
	Accept = 'accept',
	RoomSetup = 'room_setup',
	RoomReady = 'room_ready',
	StreamUpdate = 'stream_update',
	ChangeStream = 'change_stream',
	Connection = 'connection',
	AddUser = 'add_user',
	Exit = 'exit',
	PresentationEnded = 'presentation_ended',
	OptionsUpdate = 'options_update'
}

/**
 * Connection statuses that may occur with `EyesonEventType.Connection` event.
 * @see https://eyeson-team.github.io/js-docs/events/
 */
export enum EyesonConnectionStatus {
	FetchRoom = 'fetch_room',
	ReceivedRoom = 'received_room',
	Ready = 'ready',
	Connected = 'connected'
}

/**
 * Layout types that could be setup during a call.
 */
export enum RoomLayouts {
	Auto = 'auto_layout',
	Circle = 'circle_layout',
	Cross = 'cross_layout'
}

/**
 * Layout types that could be setup during a call.
 */
export enum CallSource {
	Create = 'create',
	Join = 'join'
}

/**
 * Custom layout aspectslider values for aspect ratio.
 */
export enum RoomCustomLayoutMarks {
	Small = 1,
	Medium = 45,
	Large = 70,
	ExtraLarge = 100
}

/**
 * Custom layout aspect ratio.
 */
export enum RoomCustomLayoutAspectRatio {
	Small = '3:3',
	Medium = '4:4',
	Large = '5:5',
	ExtraLarge = '6:6'
}
