import eyeson from 'eyeson';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';

import Loader from '../../components/Loader';
import { CALL_PAGE_DRAWER_WIDTH } from '../../helpers/constants';
import { useAppSelector } from '../../helpers/hooks';
import { RootWrapperStyled } from './CallPage.styles';
import ActionsToolbar from './components/ActionsToolbar';
import LayoutsDialog from './components/LayoutsDialog';
import Stream from './components/Stream';
import UsersDialog from './components/UsersDialog';

/**
 * `CallPage` is responsible for initiating `eyeson` call.
 * It displays streaming component and components to trigger `eyeson` events.
 *
 * @returns {ReactElement}
 */
const CallPage = (): ReactElement => {
	const [isUsersDialogOpened, setUsersDialogOpened] = useState(false);
	const [isLayoutsDialogOpened, setLayoutsDialogOpened] = useState(false);

	const roomAccessToken = useAppSelector((state) => state.eyeson.accessKey);
	const remoteStream = useAppSelector((state) => state.eyeson.remoteStream);
	const roomConnectLoading = useAppSelector((state) => state.loading.isRoomConnectLoading);
	const isAudioEnabled = useAppSelector((state) => state.eyeson.isAudioEnabled);
	const isVideoEnabled = useAppSelector((state) => state.eyeson.isVideoEnabled);
	const sinkId = useAppSelector((state) => state.eyeson.sinkId);

	const handleToggleUsersDialog = useCallback(() => {
		setUsersDialogOpened((prevState) => !prevState);
	}, []);

	const handleToggleLayoutsDialog = useCallback(() => {
		setLayoutsDialogOpened((prevState) => !prevState);
	}, []);

	useEffect(() => {
		if (roomAccessToken) {
			eyeson.join({ audio: isAudioEnabled, video: isVideoEnabled });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Loader isLoading={roomConnectLoading}>
			<RootWrapperStyled>
				<Stream
					src={remoteStream}
					width={`calc(100% - ${isUsersDialogOpened ? CALL_PAGE_DRAWER_WIDTH : 0}px)`}
					sinkId={sinkId}
				/>
				<ActionsToolbar
					onToggleUsersDialog={handleToggleUsersDialog}
					onToggleLayoutsDialog={handleToggleLayoutsDialog}
				/>
				<UsersDialog open={isUsersDialogOpened} onClose={() => setUsersDialogOpened(false)} />
				<LayoutsDialog open={isLayoutsDialogOpened} onClose={() => setLayoutsDialogOpened(false)} />
			</RootWrapperStyled>
		</Loader>
	);
};

export default CallPage;
