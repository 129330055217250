import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const DrawerContentStyled = styled(Box)`
  margin: ${props => props.theme.spacing(2)};
  padding: ${props => props.theme.spacing(2)};
  height: 100%;
  border-radius: 10px;
  background-color: ${props => props.theme.palette.white.main};
`;

export const DraweContentHeadStyled = styled(Box)`
  display: flex;
  flex-direction: row;
  column-gap: ${props => props.theme.spacing(2)};
  justify-content: flex-end;
`;
