import { ButtonProps as MuiButtonProps } from '@mui/material';
import React, { forwardRef, ReactElement, Ref, PropsWithChildren } from 'react';

import Loader from '../Loader';
import { Button as StyledButton } from './Button.styles';

interface ButtonProps extends MuiButtonProps {
	isLoading?: boolean;
	forwardedRef?: Ref<HTMLButtonElement>;
}

/**
 * Button component with a loading effect.
 * Uses simple `Button` from MUI and `Loader` component.
 *
 * @param {ButtonProps}
 * @returns {ReactElement}
 */
const BaseButton = ({
	forwardedRef,
	isLoading = false,
	children,
	disabled,
	...rest
}: ButtonProps): ReactElement => {
	return (
		<StyledButton ref={forwardedRef} disabled={disabled || isLoading} {...rest}>
			<Loader isLoading={isLoading} size={24}>
				{children}
			</Loader>
		</StyledButton>
	);
};

// eslint-disable-next-line react/display-name
const Button = forwardRef(
	(props: PropsWithChildren<Omit<ButtonProps, 'forwardedRef'>>, ref: Ref<HTMLButtonElement>) => (
		<BaseButton forwardedRef={ref} {...props}>
			{props.children}
		</BaseButton>
	)
);

export default Button;
