export const USER_STARTUP = 'user/startup';
export const USER_REGISTER = 'user/register';
export const USER_LOGIN = 'user/login';

export const EYESON_GET_ROOM = 'eyeson/getRoom';
export const EYESON_INIT_CHANNEL = 'eyeson/initChannel';
export const EYESON_CANCEL_CHANNEL = 'eyeson/cancelChannel';
export const EYESON_CHANGE_ROOM_LAYOUT = 'eyeson/changeRoomLayout';

export const CALL_PAGE_DRAWER_WIDTH = 500;
