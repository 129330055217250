import Logger from '../Logger.js';
import BaseEvent from './BaseEvent.js';
import MediaStreamBuilder from '../MediaStreamBuilder.js';

class ToggleCameraEvent extends BaseEvent {
  handle({ facingMode }) {
    Logger.debug('ToggleCameraEvent::handle', facingMode);
    const { _session } = this.context;

    new MediaStreamBuilder({
      audio: _session.localStream.getAudioTracks()[0].enabled,
      video: { facingMode: facingMode },
      existingStream: _session.localStream,
      deviceMonitor: _session.deviceMonitor
    })
      .start()
      .then(_session.setStream)
      .catch(Logger.error);
  }
}

export default ToggleCameraEvent;
