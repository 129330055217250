import { all } from 'redux-saga/effects';

import { watch as watchChangeRoomLayoutSaga } from './changeRoomLayoutSaga';
import { watch as watchEyesonChannel } from './eyesonChannel';
import { watch as watchGetRoomSaga } from './getRoomSaga';
import { watch as watchAuthSaga } from './loginSaga';
import { watch as watchRegisterSaga } from './registerSaga';
import { watch as watchStartupSaga } from './startupSaga';

export default function* mainSaga() {
	yield all([
		watchChangeRoomLayoutSaga(),
		watchEyesonChannel(),
		watchGetRoomSaga(),
		watchAuthSaga(),
		watchRegisterSaga(),
		watchStartupSaga()
	]);
}
