import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import mainSaga from './sagas';
import eyeson from './slices/eyeson';
import loading from './slices/loading';
import user from './slices/user';

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
	reducer: {
		user,
		loading,
		eyeson
	},
	middleware: [sagaMiddleware],
	devTools: true
});

sagaMiddleware.run(mainSaga);

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch;

export default store;
