import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import eyeson from 'eyeson';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeEvery } from 'redux-saga/effects';

import { RootState } from '../..';
import { createRoom, joinRoom } from '../../../api';
import { EYESON_GET_ROOM } from '../../../helpers/constants';
import { toastError, toastSuccess } from '../../../helpers/services/toasts';
import { initEyesonChannel } from '../../actions/eyeson';
import { setAccessKey } from '../../slices/eyeson';
import { setGetRoomLoading } from '../../slices/loading';

/**
 * Make a request
 * - to retreive an information about existed `eyeson` room and join;
 * - or to create a new `eyeson` room.
 * @param {PayloadAction<string | void>}
 */
function* makeFetch({ payload }: PayloadAction<string | void>): SagaIterator {
	try {
		yield put(setGetRoomLoading(true));

		const name = yield select((state: RootState) => state.user.username);

		const { access_key: accessKey } = payload
			? yield call(joinRoom, { name }, { key: payload })
			: yield call(createRoom, { user: { name } });

		yield put(initEyesonChannel());
		yield put(setAccessKey(accessKey));
		eyeson.connect(accessKey);
		yield call(toastSuccess, payload ? 'Joined.' : 'Room created.');
	}
	catch (error) {
		if (error instanceof AxiosError && error.response?.data?.detail) {
			yield call(toastError, error.response.data.detail);
		}
	}
	finally {
		yield put(setGetRoomLoading(false));
	}
}

export function* watch() {
	yield takeEvery(EYESON_GET_ROOM, makeFetch);
}
