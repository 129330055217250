import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const RootWrapperStyled = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background-color: ${props => props.theme.palette.purple.main};
`;
