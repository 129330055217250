import React, { ComponentType, PropsWithChildren } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';

export interface WithNavigateProps {
  navigate: NavigateFunction;
}

/**
 * Hoc that implements React Router to the class component.
 * This implementation replace deprecated `withRouter` hoc, that exist anymore in `react-router-dom@6.0.0`
 * @param Component
 */
const withNavigate = (Component: ComponentType<PropsWithChildren<WithNavigateProps>>) => {
	const Wrapper = (props: PropsWithChildren<unknown>) => {
		const navigate = useNavigate();
		return <Component {...props} navigate={navigate} />;
	};

	return Wrapper;
};

export default withNavigate;
