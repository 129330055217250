import React from 'react';
import { Outlet } from 'react-router-dom';

import { RootWrapperStyled } from './DefaultLayout.styles';

const DefaultLayout = () => {
	return (
		<RootWrapperStyled>
			<Outlet />
		</RootWrapperStyled>
	);
};

export default DefaultLayout;
