import Logger from '../Logger.js';
import PublicEvent from './PublicEvent.js';
import SigConnection from '../SigConnection.js';

const startSigConnection = (context, signaling, user) => {
  context.connection = new SigConnection(signaling, user);
  context._connection
    .onError(msg => context.send(msg))
    .start()
    .then(() => context.send({ type: 'connection', connectionStatus: 'ready' }))
    .catch(() =>
      context.send({ type: 'connection', connectionStatus: 'transport_error' })
    );
};

class RoomReceived extends PublicEvent {
  // eslint-disable-next-line max-statements
  handle(msg) {
    super.handle(msg);
    this.context.rtData = msg.content;

    if (msg.content.ready !== true) {
      Logger.debug('RoomReceived not ready:', msg.content);
      return;
    }

    const { user, room, signaling, links } = msg.content;

    // Update eyeson's public data
    this.context._eyeson.room = room;
    this.context._eyeson.user = this.context._api.addUserAttributes(
      user,
      user.id
    );
    this.context._eyeson.links = links;

    if (this.context._connection) {
      Logger.debug('RoomReceived double room_ready');
      return;
    }
    startSigConnection(this.context, signaling, user);
  }
}

export default RoomReceived;
