/**
 * Generated by orval v6.9.6 🍺
 * Do not edit manually.
 * demo-back
 * OpenAPI spec version: 1.0.0
 */
import type {
  UserOutput,
  UserBodyParams,
  CheckCookie200
} from '.././schemas'
import { customInstance } from '../../custom-instance'


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;


  /**
 * Post user's email and password pair to get user brief information and cookie with JWT token
 */
export const authenticate = (
    userBodyParams: UserBodyParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<UserOutput>(
      {url: `/auth/login`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: userBodyParams
    },
      options);
    }
  /**
 * Post unique email and password to store user in the DB
 */
export const registerUser = (
    userBodyParams: UserBodyParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<UserOutput>(
      {url: `/auth/register`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: userBodyParams
    },
      options);
    }
  /**
 * returns 200 if server detected valid JWT in cookie, 401 otherwise
 */
export const checkCookie = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CheckCookie200>(
      {url: `/auth`, method: 'get'
    },
      options);
    }
  
type AwaitedInput<T> = PromiseLike<T> | T;

    type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export type AuthenticateResult = NonNullable<Awaited<ReturnType<typeof authenticate>>>
export type RegisterUserResult = NonNullable<Awaited<ReturnType<typeof registerUser>>>
export type CheckCookieResult = NonNullable<Awaited<ReturnType<typeof checkCookie>>>
