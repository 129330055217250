/* eslint-disable max-lines */
import Logger from './Logger.js';
import NinjaStream from './NinjaStream.js';
import DeviceManager from './DeviceManager.js';
import FeatureDetector from './FeatureDetector.js';
import {
  stopTrack,
  captureStream,
  isCanvasPresentationStream,
  isScreenStream,
  isScreenPresentationStream,
  getScreenTracks,
  getScreenPresentationTracks,
  getCanvasTracks,
  getCameraTracks,
  toggleAudio,
  stopStream
} from './utils/StreamHelpers.js';

/**
 * Options are basically what the user of MediaStreamBuilder "wants" to see in
 * the conference session. For example: no camera video -> options video: false
 * but we'd still have a media stream track with video because conference server
 * currently expects this behaviour and relies on mute_video command being
 * sent.
 **/
class MediaStreamBuilder {
  constructor(
    options = {
      eco: false,
      audio: true,
      video: true,
      screen: false,
      screenStream: null,
      canvas: null,
      existingStream: null,
      micMixer: null,
      vbgMixer: null,
      isPresentation: false,
      virtualBackground: false,
      deviceMonitor: null
    }
  ) {
    this.options = options;
    this.tempStream = null;
    this.brokenTrackCallback = null;
    this.bindMethods();
  }

  bindMethods() {
    this.getMediaStream = this.getMediaStream.bind(this);
    this.addNinjaTrack = this.addNinjaTrack.bind(this);
    this.addCanvasTrack = this.addCanvasTrack.bind(this);
    this.addScreenTrack = this.addScreenTrack.bind(this);
    this.adjustAudioTrack = this.adjustAudioTrack.bind(this);
    this.adjustVideoTrack = this.adjustVideoTrack.bind(this);
    this.addScreenStreamTrack = this.addScreenStreamTrack.bind(this);
    this.initializeVirtualBackground =
      this.initializeVirtualBackground.bind(this);
  }

  /**
   * We always acquire a stream with at least audio enabled and adjust
   * the desired mute behaviour via the track.
   * Unless, we have no camera and microphones. In that case we use a
   * NinjaStream.
   * If the browser does not support updating the peerConnection
   * we acquire a audio and video stream and adjust the tracks accordingly.
   *
   * In case of replaceTrack support we stop the tracks before
   * acquiring a new stream. Since stopping a track also stops the source.
   * So, at least in Safari we'd stop the camera if the old and new track
   * use the same source.
   * Furthermore Safari on iOS behaves differently again. Camera muting
   * _sometimes_ freezes the remote stream video playback (audio fine).
   **/
  start() {
    Logger.debug('MediaStreamBuilder::start', this.options);
    const { existingStream, video, audio, screen, canvas, micMixer } =
      this.options;
    if (
      FeatureDetector.isIOSDevice() &&
      existingStream &&
      typeof video === 'boolean' &&
      existingStream.active &&
      getCameraTracks(existingStream).length > 0 &&
      getCameraTracks(existingStream)[0].readyState !== 'ended'
    ) {
      // eslint-disable-next-line max-statements
      return new Promise(resolve => {
        const [vTrack] = getCameraTracks(existingStream);
        vTrack.enabled = video;
        vTrack.onended =
          video && this.brokenTrackCallback
            ? () => {
                Logger.error('iOS video track broken');
                existingStream.getTracks().forEach(track => track.stop());
                this.brokenTrackCallback();
              }
            : null;
        if (typeof audio === 'boolean') {
          toggleAudio(existingStream, audio);
        }
        if (canvas) {
          const newStream = new MediaStream(existingStream.getTracks());
          const canvasStream = captureStream(canvas);
          const [canvasTrack] = getCanvasTracks(canvasStream);
          newStream.addTrack(canvasTrack);
          resolve(newStream);
          return;
        }
        resolve(existingStream);
      });
    }

    if (FeatureDetector.isSafari() && screen && micMixer && !canvas) {
      return this.getDisplayMedia()
        .then(displayStream => {
          const stream = new MediaStream();
          this.addScreenStreamTrack(stream, displayStream);
          this.tempStream = new MediaStream(displayStream.getTracks());
          return navigator.mediaDevices
            .getUserMedia({ audio: true })
            .then(audioStream => {
              this.adjustAudioTrack(audioStream);
              audioStream
                .getTracks()
                .forEach(track => this.tempStream.addTrack(track));
              micMixer.mixScreenshareAudio(audioStream, displayStream, stream);
              if (micMixer.active) {
                micMixer.setMicOnlyStream(audioStream, displayStream);
              }
              return stream;
            });
        })
        .then(stream => {
          this.tempStream = null;
          return stream;
        })
        .catch(error => {
          if (this.tempStream) {
            stopStream(this.tempStream);
            this.tempStream = null;
          }
          Logger.error('MediaStreamBuilder::start ', error, error.message);
          return Promise.reject(error);
        });
    }

    return DeviceManager.fetchInputDevices()
      .then(this.getMediaStream)
      .then(this.adjustVideoTrack)
      .then(this.adjustAudioTrack)
      .then(this.initializeVirtualBackground)
      .then(this.addCanvasTrack)
      .then(this.addScreenTrack)
      .then(this.addNinjaTrack)
      .then(stream => {
        this.tempStream = null;
        if (this.options.deviceMonitor) {
          this.options.deviceMonitor.applyTempTracks();
        }
        return stream;
      })
      .catch(error => {
        this.cleanupTempStream();
        if (this.options.deviceMonitor) {
          this.options.deviceMonitor.discardTempTracks();
        }
        Logger.error('MediaStreamBuilder::start ', error, error.message);
        return Promise.reject(error);
      });
  }

  // eslint-disable-next-line max-statements
  getMediaStream(devices) {
    if (devices.length === 0) {
      this.options = { audio: false, video: false };
      this.tempStream = new NinjaStream().stream;
      return this.tempStream;
    }
    const { eco, video, existingStream } = this.options;
    const options = {
      video: FeatureDetector.hasCanvasCaptureSupport() || eco ? video : true,
      audio: true
    };
    if (video === false && !eco && FeatureDetector.isIOSDevice()) {
      options.video = true;
    }
    let { getConstraints } = DeviceManager;
    if (!FeatureDetector.canMultipleDifferentMicrophones()) {
      stopStream(existingStream);
    }
    if (FeatureDetector.isPhone() || FeatureDetector.isIOSDevice()) {
      stopStream(existingStream);
      getConstraints = DeviceManager.getMobileConstraints;
    }
    return getConstraints(options).then(constraints => {
      return navigator.mediaDevices.getUserMedia(constraints).then(stream => {
        this.tempStream = stream;
        return stream;
      });
    });
  }

  /**
   * Adjust audio and video tracks according to the supplied options.
   * We stop the video track so the camera indicator isn't active.
   * We add a filler video track, unless we're in eco mode, which would result
   * in sdp with media section for video & recvonly. meaning we'd still
   * receive video.
   * In case of iOS we have the cam track that shouldn't get stopped to get turned
   * on again
   **/
  adjustVideoTrack(stream) {
    if (stream.getVideoTracks().length === 1) {
      const [vTrack] = stream.getVideoTracks();
      vTrack.enabled = this.options.video;
      if (this.options.video === false && !FeatureDetector.isIOSDevice()) {
        stopTrack(vTrack);
      }
      if (this.options.deviceMonitor) {
        this.options.deviceMonitor.addVideoTrack(vTrack);
      }
    }
    return stream;
  }

  initializeVirtualBackground(stream) {
    const { screen, virtualBackground, vbgMixer } = this.options;
    if (
      !screen &&
      virtualBackground &&
      stream &&
      stream.getVideoTracks().length === 1
    ) {
      return vbgMixer.initiateStream(stream);
    }
    return stream;
  }

  adjustAudioTrack(stream) {
    if (stream.getAudioTracks().length === 1) {
      const [aTrack] = stream.getAudioTracks();
      aTrack.enabled = this.options.audio;
      if (this.options.deviceMonitor) {
        this.options.deviceMonitor.addAudioTrack(aTrack);
      }
    }
    return stream;
  }

  addCanvasTrack(stream) {
    const { canvas, existingStream } = this.options;
    if (canvas) {
      const canvasStream = captureStream(canvas);
      const [canvasTrack] = getCanvasTracks(canvasStream);
      stream.addTrack(canvasTrack);
    }
    if (isCanvasPresentationStream(existingStream)) {
      const [canvasTrack] = getCanvasTracks(existingStream);
      stream.addTrack(canvasTrack);
    }
    return stream;
  }

  // eslint-disable-next-line max-statements
  async addScreenTrack(stream) {
    let { screenStream } = this.options;
    const { screen, existingStream, micMixer } = this.options;
    if (screen && micMixer) {
      if (screenStream === null || typeof screenStream === 'undefined') {
        screenStream = await this.getDisplayMedia();
      }
      const outStream = new MediaStream();
      this.addScreenStreamTrack(outStream, screenStream);
      micMixer.mixScreenshareAudio(stream, screenStream, outStream);
      if (micMixer.active) {
        micMixer.setMicOnlyStream(stream, screenStream);
      }
      this.tempStream = new MediaStream(
        stream.getTracks().concat(screenStream.getTracks())
      );
      return outStream;
    }
    // this is crazy! for now, we loose screen audio if there was any
    if (isScreenPresentationStream(existingStream)) {
      const [screenTrack] = getScreenPresentationTracks(existingStream);
      stream.addTrack(screenTrack);
    }
    return stream;
  }

  addScreenStreamTrack(stream, screenStream) {
    const [screenTrack] = screenStream.getVideoTracks();
    // options required for SAFARI
    screenTrack.type = this.options.isPresentation
      ? 'screen-track'
      : 'screen-video-track';
    if (stream) {
      stream.addTrack(screenTrack);
    }
  }

  getDisplayMedia() {
    if (FeatureDetector.hasGetDisplayMedia()) {
      return navigator.mediaDevices.getDisplayMedia({
        audio: true,
        video: {
          width: { max: 1920 },
          height: { max: 1080 },
          frameRate: { max: 15 }
        }
      });
    }
    return navigator.mediaDevices.getUserMedia({
      video: {
        mediaSource: 'screen',
        width: { max: 1920 },
        height: { max: 1040 },
        frameRate: { max: 15 }
      }
    });
  }

  addNinjaTrack(stream) {
    const { eco, video } = this.options;
    if (eco || !FeatureDetector.hasCanvasCaptureSupport()) {
      return stream;
    }
    if (stream.getVideoTracks().length === 0) {
      const [fillerTrack] = new NinjaStream().stream.getVideoTracks();
      fillerTrack.enabled = video;
      stream.addTrack(fillerTrack);
    }
    return stream;
  }

  // eslint-disable-next-line max-statements
  cleanupTempStream() {
    let { tempStream } = this;
    if (!tempStream) {
      return;
    }
    const { existingStream, screenStream } = this.options;
    if (isCanvasPresentationStream(existingStream)) {
      const [canvasTrack] = getCanvasTracks(existingStream);
      tempStream.removeTrack(canvasTrack);
    }
    if (isScreenStream(existingStream)) {
      const [screenTrack] = getScreenTracks(existingStream);
      tempStream.removeTrack(screenTrack);
    }
    if (isScreenPresentationStream(screenStream)) {
      const [screenTrack] = getScreenPresentationTracks(screenStream);
      tempStream.removeTrack(screenTrack);
    }
    stopStream(tempStream);
    this.tempStream = null;
  }

  onBrokenTrackError(callback) {
    this.brokenTrackCallback = callback;
    return this;
  }
}

export default MediaStreamBuilder;
