import { Component, ReactNode, PropsWithChildren } from 'react';

import { AppRoutes } from './enums';
import withNavigate, { WithNavigateProps } from './hocs/withNavigate';

/**
 * Catches an error and redirects to a custom page.
 * `withNavigate` HOC is required to implement navigation through `react-router-dom`.
 */
class ErrorBoundary extends Component<PropsWithChildren<WithNavigateProps>> {
	componentDidCatch(): void {
		this.props.navigate(AppRoutes.Error);
	}

	render(): ReactNode {
		return this.props.children;
	}
}

export default withNavigate(ErrorBoundary);
