import {
	Dialog,
	DialogContent,
	DialogProps,
	DialogTitle,
	FormControl,
	FormLabel,
	Radio,
	RadioGroup,
	Typography
} from '@mui/material';
import React, { ReactElement, useCallback, ChangeEvent } from 'react';

import { LayoutAuto, LayoutCircle, LayoutCross } from '../../../../assets/icons';
import { RoomCustomLayoutMarks, RoomLayouts } from '../../../../helpers/enums';
import { useAppDispatch, useAppSelector } from '../../../../helpers/hooks';
import { changeRoomLayout } from '../../../../store/actions/eyeson';
import { setCustomLayoutAspectRatio, setLayout } from '../../../../store/slices/eyeson';
import { FormControlLabelStyled, SliderStyled } from './LayoutsDialog.styles';

interface LayoutsDialogProps extends Omit<DialogProps, 'onClose'> {
	onClose: () => void;
}

const layoutSliderMarks = [
	{ value: RoomCustomLayoutMarks.Small, label: '3:3' },
	{ value: RoomCustomLayoutMarks.Medium, label: '4:4' },
	{ value: RoomCustomLayoutMarks.Large, label: '5:5' },
	{ value: RoomCustomLayoutMarks.ExtraLarge, label: '6:6' }
];

const LayoutsDialog = ({ onClose, ...rest }: LayoutsDialogProps): ReactElement => {
	const dispatch = useAppDispatch();

	const layout = useAppSelector((state) => state.eyeson.layout);
	const customLayoutAspectRatio = useAppSelector((state) => state.eyeson.layoutAspectRatio);

	const handleChangeLayout = useCallback((event: ChangeEvent<HTMLInputElement>) => {
		dispatch(setLayout(event.target.value as RoomLayouts));
	}, [dispatch]);

	const handleChangeCustomLayoutAspectRatio = useCallback((_: Event, value: number | number[], _activeThumb: number) => {
		if (!Array.isArray(value)) {
			dispatch(setCustomLayoutAspectRatio(value as RoomCustomLayoutMarks));
		}
	}, [dispatch]);

	const handleClose = useCallback(() => {
		dispatch(changeRoomLayout());
		onClose();
	}, [dispatch, onClose]);

	return (
		<Dialog
			onClose={handleClose}
			{...rest}
		>
			<DialogTitle>Change layout</DialogTitle>
			<DialogContent>
				<FormControl>
					<FormLabel id='choose-layout-group'>
						Choose the video layout for all participants. Pick one:
					</FormLabel>
					<RadioGroup
						aria-labelledby='choose-layout-group'
						name='choose-layout-group'
						value={layout}
						onChange={handleChangeLayout}
					>
						<FormControlLabelStyled
							value={RoomLayouts.Auto}
							control={<Radio />}
							label={
								<>
									Auto
									<LayoutAuto />
								</>
							}
						/>
						<FormControlLabelStyled
							value={RoomLayouts.Circle}
							control={<Radio />}
							label={
								<>
									Circle layout
									<LayoutCircle />
								</>
							}
						/>
						<FormControlLabelStyled
							value={RoomLayouts.Cross}
							control={<Radio />}
							label={
								<>
									Cross layout
									<LayoutCross />
								</>
							}
						/>
					</RadioGroup>
				</FormControl>
				<Typography variant='body1' color='black.light'>
					Select custom layout size:
				</Typography>
				<SliderStyled
					defaultValue={RoomCustomLayoutMarks.Medium}
					value={customLayoutAspectRatio}
					onChange={handleChangeCustomLayoutAspectRatio}
					step={null}
					valueLabelDisplay="auto"
					marks={layoutSliderMarks}
					disabled={layout === RoomLayouts.Auto}
				/>
			</DialogContent>
		</Dialog>
	);
};

export default LayoutsDialog;
