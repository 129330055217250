/**
 * Generated by orval v6.9.6 🍺
 * Do not edit manually.
 * demo-back
 * OpenAPI spec version: 1.0.0
 */
import type {
  RoomInfoOutput,
  RequestBodyRooms,
  Guest,
  JoinRoomParams,
  LayoutWithKey
} from '.././schemas'
import { customInstance } from '../../custom-instance'


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;


  /**
 * Create new room and get access_key to connect to that room
 */
export const createRoom = (
    requestBodyRooms: RequestBodyRooms,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<RoomInfoOutput>(
      {url: `/room`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: requestBodyRooms
    },
      options);
    }
  /**
 * Users can join room using guest_token without authentication
 */
export const joinRoom = (
    guest: Guest,
    params: JoinRoomParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<RoomInfoOutput>(
      {url: `/room/join`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: guest,
        params
    },
      options);
    }
  /**
 * Allows to change call layout
***
Authentication required
 */
export const changeLayout = (
    layoutWithKey: LayoutWithKey,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<void>(
      {url: `/room/layout`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: layoutWithKey
    },
      options);
    }
  
type AwaitedInput<T> = PromiseLike<T> | T;

    type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export type CreateRoomResult = NonNullable<Awaited<ReturnType<typeof createRoom>>>
export type JoinRoomResult = NonNullable<Awaited<ReturnType<typeof joinRoom>>>
export type ChangeLayoutResult = NonNullable<Awaited<ReturnType<typeof changeLayout>>>
