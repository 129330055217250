import styled from '@emotion/styled';
import { FormControlLabel, Slider } from '@mui/material';

export const FormControlLabelStyled = styled(FormControlLabel)`
	display: flex;
	margin-top: ${props => props.theme.spacing(2)};
	.MuiFormControlLabel-label {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
`;

export const SliderStyled = styled(Slider)`

`;
